import React from 'react'
import { Fragment } from 'react'
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { ToolTips } from '../../components/tooltips/ToolTips';
import { useInvoiceService } from '../../hooks/invoice/useInvoiceService';
import { SearchAsync } from '../../components/elements/SearchAsync';
import { PlaceHolderOption } from '../../constant';
import { ModalPatient } from '../../components/patients/ModalPatient';
import { ModalCompany } from '../../components/companies/ModalCompany';
import FormatNumbers, { FormatAmount } from '../../utils/formatNumbers';
import ReceptorInvoice from '../../utils/enum/ReceptorInvoice';
import Select from 'react-select';
import TypePersonInvoiceEnum from '../../utils/enum/type_person.enum';
import moment from 'moment';
import { ModalAddExtraPatient } from '../../components/Invoice/ModalAddExtraPatient';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { TypeVIsualizationEnum } from '../../utils/enum/Invoice_enum';
import { ModalPreviewInvoice } from '../../components/Invoice/ModalPreviewInvoice';
import { ModalLoading } from '../../components/Invoice/ModalLoading';
import { ModalPreviewInvoiceGlobal } from '../../components/Invoice/ModalPreviewInvoiceGlobal';
import { ModalPreviewInvoicePatient } from '../../components/Invoice/ModalPreviewInvoicePatient';

export const IndexInvoiceService = () => {

    const {
        showUpdateFiscalInformationPatient, showUpdaetFiscalInformationClient, showExtraPatient, showPreviewInvoice, byDashboard, disableStampOrder,

        setShowCardFilterByDate, showCardFilterByDate, isEditInvoice, showError,

        filterByBranch, setFilterByBranch,

        filterByPayMethod, setFilterByPayMethod,
        
        viewInformationReceptor, viewInformationIssuing,

        listCommercialLines, listClients, listClientCompanyBUsinessName, listPayMethods,
        listPayForms, listUseOfCfdis, listFilterNims, listTaxRegimes, listOfPeriodicity, listOfMonths,
        listBranches, listPayFormsWorkOrder,

        listWorkOrdersTemp,

        filterTypeReceptor, searchPatientState, searchExtraPatient, searchValueInput,

        selectCompany, selectCommercialNameCompany,

        formInvoice, formPatientFiscalInformation, formCompanyFiscalInformation,

        filterDateRanges, setFilterDateRanges, optionsDue,

        isLoadingWorkOrders,

        loadingPreview, showLoading,

        handleChangeTyepeReceptor,
        handleChangePatient,
        handleChangeClient,
        handleChangeIssuing,
        handleChangeClientBusinessId,
        handleViewFilters,
        handleChangeInput,
        handleChangeFilterNims,
        handleUpdateFiscalInformation,
        handleToggleModal,
        handleChangeInputEditPatient,
        handleChangeInputEditClient,

        handleAddNewWorkOrder,
        handleChangeAddNewWorkOrder,
        handleConfirmExtraPatients,

        handleFilterDates,
        handleFilterByGeneralPublic,
        handleChangeTypeVisualization,
        handleDeleteTest,

        handlePreviewInvoice, handleLoadingPreInvoice,

        saveUpdateFiscalInformation, saveUpdateClientInformation,
        saveInvoice,

        validationsPatient,
        validationsBusinessName,

        checkedItems,handleCheckboxChange,saveInvoicesGlobal,
        handlePreviewGlobalInvoices,loadingPreviewInvoices,
        showPreviewInvoicesGlobal,handleCancelGlobal,loadingGlobal,

        handleDateRemission,twoDaysAgo,today,handleViewDate,handleClosed,

        handleCheckboxChangePatient,checkedItemsPatient,saveInvoicesPatient,handlePreviewPatientGlobal,
        showPreviewInvoicesPatient,loadingGlobalPatient,handleCancelPatient,loadingPreviewInvoicesP




    } = useInvoiceService();
        console.log("🚀 ~ IndexInvoiceService ~ checkedItemsPatient:", Object.keys(checkedItemsPatient).length)

    

    const filterOptions = ({ label, value, data }, input = ""
    ) => {
        if (input) {
            return data?.baseOption.includes(input);
        }
        return true;
    };

    const listCardClients = [
        {
            name: "Paciente particular",
            value: ReceptorInvoice.PATIENT,
            icon: '/faviconh.png'
        },
        {
            name: "Empresas",
            value: ReceptorInvoice.COMPANY,
            icon: '/faviconh.png'
        },
        {
            name: "Público en general",
            value: ReceptorInvoice.GENERAL_PUBLIC,
            icon: '/faviconh.png'
        }
    ];

    const divInformationReceptor = <>
        <Col sm="12">
            <Row>
                <Col sm="12" md="12" lg="12" className='email-body'>
                    <div className='media'>
                        <div className='media-body text-center'>
                            <h6><i className="fa fa-circle-o text-info"></i> {formInvoice.receptor_information?.name_commercial_line ?? ''}</h6>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='project-box p-1' style={{ borderWidth: '0px', opacity: "0.8" }}>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <small>Régimen fiscal</small> <br />
                        <label style={{ fontSize: "11px" }}>
                            <i className="fa fa-circle text-info"></i>  {formInvoice.receptor_information?.code_fiscal_regime ?? ''} - {formInvoice.receptor_information?.name_fiscal_regime ?? ''}
                        </label>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col sm="5" md="5" lg="5">
                        <small>RFC</small> <br />
                        <label><i className="fa fa-circle text-info"></i> {formInvoice.receptor_information?.rfc ?? ''}</label>
                    </Col>
                    <Col sm="5" md="5" lg="5">
                        <small>Código postal</small> <br />
                        <label style={{ fontSize: "11px" }}><i className="fa fa-circle text-info"></i> {formInvoice.receptor_information?.expedition_place ?? ''}</label>
                    </Col>
                    <Col sm="2" md="2" lg="2">
                        {
                            formInvoice.type !== ReceptorInvoice.GENERAL_PUBLIC && !isEditInvoice && <Button disabled={byDashboard} id='btn-tooltip-filter-p' onClick={() => handleViewFilters()} outline color='secondary' className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
                            >
                                <i className="icofont icofont-filter"></i>
                                <ToolTips
                                    placement="top"
                                    dataTarget='btn-tooltip-filter-p'
                                    dataText={`Seleccionar ${formInvoice.type === ReceptorInvoice.PATIENT ? 'otro paciente' : 'otra empresa'}`}
                                />
                            </Button>
                        }
                        {
                            formInvoice.type !== ReceptorInvoice.GENERAL_PUBLIC && <Button id='btn-tooltip-edit-p' onClick={() => handleUpdateFiscalInformation()} outline color='primary' className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}
                            >
                                <i className="icofont icofont-ui-edit "></i>
                                <ToolTips
                                    placement="bottom"
                                    dataTarget='btn-tooltip-edit-p'
                                    dataText='Editar datos fiscales'
                                />
                            </Button>
                        }
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col sm="5" md="5" lg="5">
                        <small>Tipo de persona</small> <br />
                        <label><i className="fa fa-circle text-info"></i> {formInvoice.receptor_information?.type_person ?? ''}</label>
                    </Col>
                </Row>
            </div>
        </Col>
    </>

    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0' >
                {
                    showError && <div className='text-center'>
                        <div style={{ textAlign: "-webkit-center" }} className='d-block'>
							<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "32%" }} alt="" />
						</div>
							<p className='f-w-600 f-14 badge badge-light-primary'>No existen resultados para su busqueda.</p>
                    </div>
                }
                <Row className='align-content-center'>
                    {filterTypeReceptor === '' && !showError &&<Col sm="12">
                    <div style={{backgroundColor: "#0029408f",borderRadius: "8px 8px 0 0", padding: "15px",color: "#fff",boxShadow: "0 4px 8px rgba(0,0,0,0.2)"}}>
                    <div style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)",paddingBottom: "10px",marginBottom: "10px",fontSize: "1.25em",fontWeight: "bold",textAlign: "center", }}>
                    Selecciona el tipo de factura a realizar 
                    </div>
                    </div>
                    <div style={{backgroundColor: "#46afe53d",  borderRadius: "0 0 8px 8px", padding: "15px",color: "#000", height: "400px"}}>
                    <Row className='pt-5'>
                        <Col sm="2">
                        </Col>
                        {
                            filterTypeReceptor === '' && !showError && (
                                listCardClients.map((client, index) => {
                                    return <Col key={`card-client-${index}`} xs="3" md="3" xl="3" className='cursor-pointer pointer' onClick={() => handleChangeTyepeReceptor(client.value)}>
                                        <Card className='shadow'>
                                            <Row>
                                                <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
                                                    <img className="img-fluid mt-2 ml-4" src={(`${client.icon}`)} alt={client.name} style={{ width: '50%', height: '75%' }} />
                                                </Col>
                                                <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0'>
                                                    <h6 className='labelIndicadorCash mt-4'>{client.name}</h6>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                })
                            )
                        }
                        </Row>
                    </div>
                    </Col>}
                </Row>
                {
                    filterTypeReceptor !== '' && !isEditInvoice && <div className='text-right mb-2'>
                        <Button color='light' type="button" size='sm'  onClick={() => handleChangeTyepeReceptor('')}>
                            <i className="fa fa-filter f-14"></i> &nbsp;&nbsp; Filtro facturación
                        </Button>
                    </div>
                }
                {
                    filterTypeReceptor !== '' && <Row className='mb-5'>
                        <Col sm="2" md="2" lg="2" className='default-according style-1 faq-accordion job-accordion position-relative'>
                            <Row className="position-sticky" style={{ top: 120 }}>
                                <Col xl="12">                                
                                    <Card>
                                        <CardHeader className='bg-secondary'>
                                            <h5 className="mb-0 f-12">
                                                Acciones
                                            </h5>
                                        </CardHeader>
                                        <CardBody style={{ padding: "8px" }}>
                                            <Row>
                                                <Col sm="12" md="12" >
                                                    <Button className={`m-b-10`} color='info' outline onClick={() => handlePreviewInvoice()}><i className="icofont icofont-optic"></i> &nbsp;&nbsp; Previsualizar</Button>

                                                    {filterTypeReceptor === ReceptorInvoice.COMPANY &&<Button disabled={disableStampOrder} className=' m-b-10' color='warning' outline onClick={() => saveInvoice(true)}>  <i className="icofont icofont-file-pdf"></i> Prefactura </Button>}

                                                    <Button disabled={disableStampOrder} className='' color='success' outline onClick={() => saveInvoice(false)}>  <i className="icofont icofont-list"></i> &nbsp;&nbsp; Timbrar </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="12">
                                    <Card>
                                        <CardHeader className='bg-secondary'>
                                            <h5 className="mb-0 f-12">
                                                Tipo de visualización
                                            </h5>
                                        </CardHeader>
                                        <CardBody style={{ padding: "8px" }}>
                                            <Row>
                                                <Col sm="12" md="12" >
                                                    <div className=''>
                                                        <p className='f-w-500'>Concepto</p>
                                                        <hr className='p-0' />
                                                        <div className='w-100'>
                                                            {
                                                                filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && (                                                                    
                                                                    <Button color='info' outline size='sm' className={`text-xs ${formInvoice.include_name_patient ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeInput(!formInvoice.include_name_patient, 'include_name_patient')}>
                                                                        Nombre del paciente
                                                                    </Button>
                                                                )
                                                            }
                                                            {
                                                                filterTypeReceptor === ReceptorInvoice.GENERAL_PUBLIC && (
                                                                    <>
                                                                        <Button color='info' outline size='sm' className={`text-xs ${formInvoice.include_detail_period ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeInput(!formInvoice.include_detail_period, 'include_detail_period')}>
                                                                            Detalle por periodo
                                                                        </Button>
                                                                        <Button color='info' outline size='sm' className={`text-xs ${formInvoice.include_sells ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeInput(!formInvoice.include_sells, 'include_sells')}>
                                                                            Detalle por ventas
                                                                        </Button>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className={filterTypeReceptor === ReceptorInvoice.COMPANY ? "" : "d-none"}>
                                                        <p className='f-w-500'>Visualización</p>
                                                        <hr className='p-0' />
                                                        {
                                                            filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && (
                                                                <div className='w-100'>
                                                                    <Button color='dark' outline size='sm' className={`text-xs ${formInvoice.type_visualization?.includes(TypeVIsualizationEnum.DETAIL) ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeVisualization(`${TypeVIsualizationEnum.DETAIL}`, 'type_visualization')}>
                                                                        <i className="icofont icofont-document-search ml-2"></i> Detallado
                                                                    </Button>
                                                                    <Button color='dark' outline size='sm' className={`text-xs ${formInvoice.type_visualization?.includes(TypeVIsualizationEnum.GLOBAL) ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeVisualization(`${TypeVIsualizationEnum.GLOBAL}`, 'type_visualization')}>
                                                                        <i className="icofont icofont-paperclip ml-2"></i>Global
                                                                    </Button>
                                                                    <Button color='dark' outline size='sm' className={`text-xs ${formInvoice.type_visualization?.includes(TypeVIsualizationEnum.TEST) ? 'btn-dark-gradien' : 'btn-outline-info-2x'}`} onClick={() => handleChangeTypeVisualization(`${TypeVIsualizationEnum.TEST}`, 'type_visualization')}>
                                                                        <i className="icofont icofont-clip-board ml-2"></i> Por estudio
                                                                    </Button>
                                                                </div>          
                                                            )
                                                        }                                                   
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="12" className={Object.keys(checkedItems).length > 0 ? "" : "d-none"} >
                                    <Card>
                                        <CardHeader className='bg-secondary'>
                                            <h5 className="mb-0 f-12">
                                                Facturar a Público General
                                            </h5>
                                        </CardHeader>
                                        <CardBody style={{ padding: "8px" }}>
                                            <Row>
                                                <Col sm="12" md="12" >
                                                    <Button  color='dark' outline onClick={() => handlePreviewGlobalInvoices()}><i className="icofont icofont-users"></i> &nbsp;&nbsp; Público General</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="12" className={Object.keys(checkedItemsPatient).length > 0 ? "" : "d-none"} >
                                    <Card>
                                        <CardHeader className='bg-secondary'>
                                            <h5 className="mb-0 f-12">
                                                Facturara al paciente
                                            </h5>
                                        </CardHeader>
                                        <CardBody style={{ padding: "8px" }}>
                                            <Row>
                                                <Col sm="12" md="12" >
                                                    <Button  color='danger' outline onClick={() => handlePreviewPatientGlobal()}><i className="fa fa-file-pdf-o"></i> &nbsp;&nbsp; Modificar</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="10" md="10" lg="10">
                            <Card>
                            
                                <CardBody style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row>
                                        <Col md={formInvoice.patient_id !== null || (formInvoice.client_id !== null && formInvoice.client_business_id !== null) ? 6 : 4}>
                                            <div className='badge badge-success f-12 mb-2 w-100'>Receptor</div>
                                            <Row>
                                                {
                                                    !viewInformationReceptor && filterTypeReceptor === ReceptorInvoice.PATIENT && <Col sm="12">
                                                        <div className='form-group'>
                                                            <Label>{filterTypeReceptor === ReceptorInvoice.COMPANY ? 'Cliente' : 'Paciente'} <span className='text-info'>*</span></Label>
                                                            <SearchAsync
                                                                cacheOptions={false}
                                                                name="patient_id"
                                                                value={formInvoice.patient_id}
                                                                method={handleChangePatient}
                                                                loincState={searchPatientState}
                                                                url="Patient/SearchPatient"
                                                                maxLenght={3}
                                                                placeholder='Búsqueda por nombre o CURP'
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                                {
                                                    !viewInformationReceptor && filterTypeReceptor === ReceptorInvoice.COMPANY && <>
                                                        <Col sm="12"> 
                                                            <div className='form-group'>
                                                                <Label>Empresa <span className='text-info'>*</span></Label>
                                                                <Select
                                                                    className="input-air-primary input-xs"
                                                                    classNamePrefix="select"
                                                                    name="client_id"
                                                                    value={selectCompany}
                                                                    options={listClients}
                                                                    placeholder={PlaceHolderOption}
                                                                    isClearable={true}
                                                                    onChange={(e) => handleChangeClient(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                            <div className='form-group'>
                                                                <Label>Nombre / Razón social <span className='text-info'>*</span></Label>
                                                                <Select
                                                                    className="input-air-primary input-xs"
                                                                    classNamePrefix="select"
                                                                    name="client_business_id"
                                                                    value={selectCommercialNameCompany}
                                                                    options={listClientCompanyBUsinessName}
                                                                    placeholder={PlaceHolderOption}
                                                                    isClearable={true}
                                                                    onChange={(e) => handleChangeClientBusinessId(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    viewInformationReceptor && divInformationReceptor
                                                }
                                            </Row>
                                        </Col>
                                        <Col md={formInvoice.patient_id !== null || (formInvoice.client_id !== null && formInvoice.client_business_id !== null) ? 2 : 4}></Col>
                                        <Col md="4">
                                            <div className='badge badge-primary f-right f-12 mb-2 w-100'>Emisor</div>
                                            <br />
                                            <Row>
                                                {
                                                    formInvoice.commercial_line_id === null && <Col sm="12">
                                                        <div className='form-group'>
                                                            <Label>Razón social <span className='text-info'>*</span></Label>
                                                            <Select
                                                                className="input-air-primary input-xs"
                                                                classNamePrefix="select"
                                                                name="commercial_line_id"
                                                                value={formInvoice?.commercial_line_id ?? 0}
                                                                options={listCommercialLines}
                                                                placeholder={PlaceHolderOption}
                                                                isClearable={true}
                                                                onChange={(e) => handleChangeIssuing(e)}
                                                            />
                                                        </div>
                                                    </Col>
                                                }
                                                {
                                                    viewInformationIssuing && <Col sm="12">
                                                        <Row>
                                                            <Col sm="12" md="12" lg="12" className='email-body'>
                                                                <div className='media'>
                                                                    <div className='media-body text-center'>
                                                                        <h6><i className="fa fa-circle-o text-info"></i> {formInvoice?.issuing_information?.name ?? ''}</h6>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className='project-box p-1' style={{ borderWidth: '0px', opacity: "0.8" }}>
                                                            <Row>
                                                                <Col sm="12" md="12" lg="12">
                                                                    <small>Régimen fiscal</small> <br />
                                                                    <label style={{ fontSize: "11px" }}>
                                                                        <i className="fa fa-circle text-info"></i>  {formInvoice?.issuing_information?.code_tax_regime ?? ''} - {formInvoice?.issuing_information?.name_tax_regime ?? ''}
                                                                    </label>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-1'>
                                                                <Col sm="5" md="5" lg="5">
                                                                    <small>RFC</small> <br />
                                                                    <label><i className="fa fa-circle text-info"></i> {formInvoice?.issuing_information?.rfc ?? ''}</label>
                                                                </Col>
                                                                <Col sm="5" md="5" lg="5">
                                                                    <small>Código postal</small> <br />
                                                                    <label style={{ fontSize: "11px" }}><i className="fa fa-circle text-info"></i> {formInvoice?.issuing_information?.cp ?? ''}</label>
                                                                </Col>
                                                                <Col sm="2" md="2" lg="2">
                                                                    <Button id='btn-tooltip-filter-p' outline color='secondary' className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
                                                                    >
                                                                        <i className="icofont icofont-filter"></i>
                                                                        <ToolTips
                                                                            placement="top"
                                                                            dataTarget='btn-tooltip-filter-p'
                                                                            dataText='Seleccionar otro paciente'
                                                                        />
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-1'>
                                                                <Col sm="5" md="5" lg="5">
                                                                    <small>Tipo de persona</small> <br />
                                                                    <label><i className="fa fa-circle text-info"></i> {formInvoice?.issuing_information?.type_person ?? ''}</label>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm="12">
                                            <Row>
                                                <Col sm="12">
                                                    <h6>Datos del documento</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    filterTypeReceptor === ReceptorInvoice.GENERAL_PUBLIC && (
                                                        <>
                                                            <Col sm="3" className='light'>
                                                                <div className='form-group'>
                                                                    <Label>Periodicidad <span className='text-info'>*</span></Label>
                                                                    <Select
                                                                        className="input-air-primary input-xs"
                                                                        classNamePrefix="select"
                                                                        name="periodicity_id"
                                                                        value={formInvoice.periodicity_id}
                                                                        options={listOfPeriodicity}
                                                                        placeholder={PlaceHolderOption}
                                                                        isClearable={true}
                                                                        onChange={(e) => handleChangeInput(e, 'periodicity_id')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm="3" className='light'>
                                                                <div className='form-group'>
                                                                    <Label>Mes <span className='text-info'>*</span></Label>
                                                                    <Select
                                                                        className="input-air-primary input-xs"
                                                                        classNamePrefix="select"
                                                                        name="month_id"
                                                                        value={formInvoice.month_id}
                                                                        options={listOfMonths}
                                                                        placeholder={PlaceHolderOption}
                                                                        isClearable={true}
                                                                        onChange={(e) => handleChangeInput(e, 'month_id')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm="3" className='light'>
                                                                <div className='form-group'>
                                                                    <Label>Periodo de facturación <span className='text-info'>*</span></Label>
                                                                    <Select
                                                                        className="input-air-primary input-xs"
                                                                        classNamePrefix="select"
                                                                        name="year_billing"
                                                                        value={formInvoice.year_billing}
                                                                        options={[
                                                                            {
                                                                                label: moment().add(-1,'year').format('YYYY'),
                                                                                value: moment().add(-1, 'year').format('YYYY')
                                                                            },
                                                                            {
                                                                                label: moment().format('YYYY'),
                                                                                value: moment().format('YYYY'),
                                                                            },
                                                                            {
                                                                                label: moment().add(1, 'year').format('YYYY'),
                                                                                value: moment().add(1, 'year').format('YYYY')
                                                                            },
                                                                        ]}
                                                                        placeholder={PlaceHolderOption}
                                                                        isClearable={true}
                                                                        onChange={(e) => handleChangeInput(e, 'year_billing')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                                {
                                                    filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && (
                                                        <>
                                                            <Col sm="4" className='light'>
                                                                <div className='form-group'>
                                                                    <Label>Uso del CFDI <span className='text-info'>*</span></Label>
                                                                    <Select
                                                                        className="input-air-primary input-xs"
                                                                        classNamePrefix="select"
                                                                        name="use_cfdi_id"
                                                                        value={formInvoice.use_cfdi_id}
                                                                        options={listUseOfCfdis}
                                                                        placeholder={PlaceHolderOption}
                                                                        isClearable={false}
                                                                        onChange={(e) => handleChangeInput(e, 'use_cfdi_id')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm="3" className='light'>
                                                                <div className='form-group'>
                                                                    <Label>Método de pago <span className='text-info'>*</span></Label>
                                                                    <Select
                                                                        className="input-air-primary input-xs"
                                                                        classNamePrefix="select"
                                                                        name="pay_method_id"
                                                                        value={formInvoice.pay_method_id}
                                                                        options={listPayMethods}
                                                                        placeholder={PlaceHolderOption}
                                                                        isClearable={false}
                                                                        onChange={(e) => handleChangeInput(e, 'pay_method_id')}
                                                                    />                                                    
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                                <Col sm="3" className='light'>
                                                    <div className='form-group'>
                                                        <Label>Forma de pago <span className='text-info'>*</span></Label>
                                                        <Select
                                                            className="input-air-primary input-xs"
                                                            classNamePrefix="select"
                                                            name="pay_form_id"
                                                            value={formInvoice.pay_form_id}
                                                            options={listPayForms}
                                                            placeholder={PlaceHolderOption}
                                                            isClearable={true}
                                                            onChange={(e) => handleChangeInput(e, 'pay_form_id')}
                                                        />   
                                                    </div>
                                                </Col>
                                                <Col sm="3" className='light'>
                                                    <div className='form-group'>
                                                        <Col sm="12" className='p-l-0 p-r-0' >
                                                        <Row>
                                                            <Col sm="7">
                                                            <Label className='f-w-500 txt-info' >Fecha de emisión </Label>
                                                            </Col>
                                                            <Col sm="2" className='pt-2' >
                                                                {  
                                                                    <Button id='btn-tooltip-filter-date'  outline color={formInvoice?.is_date ? 'danger' : 'secondary'}  className='p-3' onClick={ formInvoice?.is_date ? handleClosed :  handleViewDate} style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}>
                                                                    <i className={formInvoice?.is_date ? "fa fa-times" : "fa fa-calendar-o"} ></i>    
                                                                    <ToolTips
                                                                        placement="top"
                                                                        dataTarget='btn-tooltip-filter-date'
                                                                        dataText={`${formInvoice?.is_date  ? 'Cancelar movimiento' : 'Modificar fecha de emisión'}`}
                                                                    />  
                                                                    </Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        </Col>
                                                        {
                                                            formInvoice?.is_date ? 
                                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                                <Row>
                                                                    <Col sm="6" className='p-r-0' >
                                                                    <DateRangePicker
                                                                        locale={{
                                                                        format: 'YYYY-MM-DD',
                                                                        cancelLabel: 'Borrar',
                                                                        }}
                                                                        startDate={formInvoice?.date_remission}
                                                                        endDate={formInvoice?.date_remission}
                                                                        onApply={handleDateRemission ?? ''}
                                                                        initialSettings={{
                                                                        autoApply: true,
                                                                        singleDatePicker: true,
                                                                        minDate: twoDaysAgo,
                                                                        maxDate: today, 
                                                                        }}
                                                                        id="singleDatePicker"
                                                                    >
                                                                        <input
                                                                        type="text"
                                                                        readOnly
                                                                        className="form-control"
                                                                        value={formInvoice?.date_remission }
                                                                        style={{ fontSize: '11px' }}  
                                                                        />
                                                                    </DateRangePicker> 
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        : 
                                                        <p className='f-w-600'>
                                                            { moment().format("DD-MM-YYYY") }
                                                        </p>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm="6" className='light'>
                                                    <div className='form-group'>
                                                        <Label>Condiciones de pago</Label>
                                                        <Input type='text' autoComplete='off' size="sm" name='payment_conditions' placeholder='Condiciones de pago' value={formInvoice?.payment_conditions ?? ''} onChange={(e) => handleChangeInput(e?.target?.value, 'payment_conditions')}  />
                                                    </div>
                                                </Col>                                            
                                                <Col sm="3" className='light'>
                                                    <div className='form-group'>
                                                        <Label className='txt-info ' >Total</Label>
                                                        <p className='f-w-600'>$ {FormatNumbers(optionsDue.total, 2)}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                           
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col sm="9">
                                                    <h6>Conceptos</h6>                                                
                                                </Col>
                                                <Col>
                                                    {
                                                        filterTypeReceptor === ReceptorInvoice.PATIENT &&    
                                                        <Button color='primary' id='btn-tooltip-filter-new-patients' onClick={handleAddNewWorkOrder} type='button' className='btn btn-pill btn-sm' >
                                                            <i className="fa fa-group mr-2"></i>
                                                            Agregar otros pacientes
                                                        </Button>
                                                    }
                                                    {
                                                        filterTypeReceptor === ReceptorInvoice.COMPANY &&    
                                                        <Button color={ showCardFilterByDate ? 'danger' : 'primary' } id='btn-tooltip-filter-new-patients' onClick={() => { setShowCardFilterByDate(!showCardFilterByDate) }} type='button' className='btn btn-pill btn-sm' >
                                                            <i className={showCardFilterByDate ? 'fa fa-times mr-2' : 'icofont icofont-filter mr-2'}></i>
                                                            {
                                                                showCardFilterByDate ? 'Filtro por NIM' : 'Rango de fechas'
                                                            }
                                                        </Button>
                                                    }

                                                </Col>
                                            </Row>                                        
                                            <Row>
                                                <Col sm={ filterTypeReceptor === ReceptorInvoice.GENERAL_PUBLIC ? 12 : 8 }>
                                                    {
                                                        !showCardFilterByDate && filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && <div className='form-group'>
                                                            <label className='txt-primary' >NIMS asociados al paciente</label>
                                                            <Select
                                                                className="input-air-primary input-xs"
                                                                classNamePrefix="select"
                                                                name="use_cfdi_id"
                                                                // value={formInvoice.use_cfdi_id}
                                                                options={listFilterNims}
                                                                placeholder={PlaceHolderOption}
                                                                isClearable={true}
                                                                onChange={(e) => handleChangeFilterNims(e)}
                                                                filterOption={filterOptions}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        showCardFilterByDate && <div className={`animate__animated animate__fadeIn form-group`}>
                                                            <label className='txt-primary' htmlFor="dateRange">Selecciona un rango de fechas</label>
                                                            <DateRangePicker
                                                                locale={{
                                                                    format: "YYYY-MM-DD",
                                                                    cancelLabel: 'Borrar'
                                                                }}
                                                                startDate={filterDateRanges.startDate}
                                                                endDate={filterDateRanges.endDate}
                                                                onApply={handleFilterDates}
                                                                initialSettings={{
                                                                    autoApply:true,     
                                                                    
                                                                }}
                                                                id="dateRange"
                                                            >
                                                                <input type='text' readOnly className='form-control' value={`${filterDateRanges.startDate}-${filterDateRanges.endDate}`}/>
                                                            </DateRangePicker>
                                                        </div>
                                                    }
                                                    {
                                                        filterTypeReceptor === ReceptorInvoice.GENERAL_PUBLIC  && formInvoice.month_id?.value !== undefined && <Row>
                                                            <Col sm="2" md="2" lg="2">
                                                                <label className='txt-primary' htmlFor="dateRange">Fecha inicio</label>                                                                
                                                                <FormGroup>
                                                                    <Input
                                                                        className='form-control form-control-sm input-air-primary'
                                                                        type='date'
                                                                        name='date_periodicity'
                                                                        value={filterDateRanges.startDate}
                                                                        onChange={(e) => {
                                                                            setFilterDateRanges({
                                                                                ...filterDateRanges,
                                                                                startDate: e.target.value,
                                                                                endDate: formInvoice?.periodicity_id === "01" ? e.value : filterDateRanges.endDate
                                                                            })
                                                                        }}
                                                                        readOnly={ formInvoice?.periodicity_id?.value === "04" || formInvoice?.periodicity_id?.value === "05" ? true : false}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            {
                                                                formInvoice?.periodicity_id?.value !== "01" && <Col sm="2" md="2" lg="2"> 
                                                                    <label className='txt-primary' htmlFor="dateRange">Fecha cierre</label>  
                                                                    <FormGroup>
                                                                        <Input
                                                                            className='form-control form-control-sm input-air-primary'
                                                                            type='date'
                                                                            name='date_periodicity'
                                                                            value={filterDateRanges.endDate}
                                                                            onChange={(e) => {
                                                                                setFilterDateRanges({
                                                                                    ...filterDateRanges,
                                                                                    endDate: e.target.value
                                                                                })
                                                                            }}
                                                                            readOnly={ formInvoice?.periodicity_id?.value === "04" || formInvoice?.periodicity_id?.value === "05" ? true : false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            }    
                                                            <Col sm="3" md="3" lg="3">
                                                                <label className='txt-primary' htmlFor="dateRange">Filtro por sucursal</label>                                                                
                                                                <FormGroup>
                                                                    <Select
                                                                        isClearable={true}
                                                                        placeholder="Selecciona una sucursal"
                                                                        noOptionsMessage="Sin resultados"
                                                                        options={listBranches}
                                                                        onChange={(e) => {
                                                                            setFilterByBranch(e)
                                                                        }}
                                                                        value={filterByBranch}
                                                                        className="b-r-9"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3" md="3" lg="3">
                                                                <label className='txt-primary' htmlFor="dateRange">Método de pago</label>                                                                
                                                                <FormGroup>
                                                                    <Select
                                                                        isClearable={true}
                                                                        placeholder="Selecciona una opción"
                                                                        noOptionsMessage="Sin resultados"
                                                                        options={listPayFormsWorkOrder}
                                                                        onChange={(e) => {
                                                                            setFilterByPayMethod(e)
                                                                        }}
                                                                        value={filterByPayMethod}
                                                                        className="b-r-9"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="2" md="2" lg="2">
                                                                <Button outline color='primary' type='button' className='btn btn-sm mt-4' onClick={() => handleFilterByGeneralPublic()}>
                                                                    <span className='fa fa-filter'></span> Filtrar 
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }   
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm="12">
                                                    <div className='table-responsive'>
                                                        <Table className='table-sm table-stripped' >
                                                            <thead className="theadInvoice">
                                                                <tr>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative", borderTopLeftRadius: "10px" }}></th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>Cantidad</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>Clave de producto</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>Clave unidad</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>No. Identificación</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>Concepto</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>Valor unitario</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative" }}>Descuento</th>
                                                                    <th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative"}}>Importe</th>
                                                                    <th className='text-center labeltheadInvoice' style={ filterTypeReceptor === ReceptorInvoice.COMPANY  ? {padding: "18px 15px", textAlign: "center", position: "relative"} : { padding: "18px 15px", textAlign: "center", position: "relative", borderTopRightRadius: "10px" }}></th>
                                                                    { filterTypeReceptor === ReceptorInvoice.COMPANY  &&<th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative"}}>F. P.General</th>}
                                                                    { filterTypeReceptor === ReceptorInvoice.COMPANY  &&<th className='text-center labeltheadInvoice' style={{ padding: "18px 15px", textAlign: "center", position: "relative", borderTopRightRadius: "10px" }}>F. Paciente</th>}

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    isLoadingWorkOrders &&  <tr>
                                                                        <td colSpan={ filterTypeReceptor === ReceptorInvoice.COMPANY ? 12 :10} className='bg-info text-center f-16'>
                                                                            <i className="fa fa-spin fa-circle-o-notch"></i> Agregando admisiones ...
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    !isLoadingWorkOrders && filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && formInvoice.type_visualization === TypeVIsualizationEnum.DETAIL && formInvoice.listOrders?.length > 0 && formInvoice?.listOrders?.map((order, index) => {
                                                                        
                                                                        const idWorkOrder = order?.id_work_order;
                                                                        const idWorkOrderP = `${order?.id_work_order}-patient`; 

                                                                        const isChecked = !!checkedItems[idWorkOrder]; 
                                                                        const isCheckedPatient = !!checkedItemsPatient[idWorkOrderP]; 


                                                                        return !order?.is_deleted && <tr key={`${index}_${order.id_work_order_profile !== null ? order.id_work_order_profile : order.id_work_order_exam }`} className='text-center f-w-600'>
                                                                            <td className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>
                                                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/flask_laboratory.svg")} style={{ width: "30px", height: "30px" }} />
                                                                            </td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>{1}</td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>{order?.class_product_code_service}</td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>
                                                                                <span>{order?.code_unit}</span> <br />
                                                                                <span>{order?.name_unit}</span>
                                                                            </td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>{order?.identification_code}</td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>
                                                                                {order?.name}
                                                                                <br />
                                                                                { formInvoice?.include_name_patient && <small>{order?.name_patient ?? ''}</small> }
                                                                            </td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>$ {FormatAmount(order?.price ?? 0, 6)}</td>
                                                                            <td className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>$ {order?.total_discount}</td>
                                                                            <td className='bg-info'>$ {FormatAmount(order?.import ?? 0, 6)}</td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>
                                                                                <Button size='xs' color='danger' onClick={() => handleDeleteTest(
                                                                                    order?.id_exam ?? null, 
                                                                                    order?.id_profile ?? null,
                                                                                    order?.id_work_order_exam ?? null,
                                                                                    order?.id_work_order_profile ?? null
                                                                                )}>
                                                                                    <i className='icofont icofont-trash'></i>
                                                                                </Button>
                                                                            </td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>
                                                                                <input
                                                                                type="checkbox"
                                                                                id={`checkbox-${idWorkOrder}`} 
                                                                                checked={!!checkedItems[idWorkOrder]} 
                                                                                onChange={() => handleCheckboxChange(idWorkOrder)} 
                                                                                style={{ display: 'none' }}
                                                                                />
                                                                                <label htmlFor={`checkbox-${idWorkOrder}`} style={{
                                                                                display: 'inline-block',
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                borderRadius: '50%',
                                                                                border: `2px solid #065c88`,
                                                                                backgroundColor: 'transparent',
                                                                                cursor: 'pointer',
                                                                                position: 'relative'
                                                                                }}>
                                                                                <span style={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    width: '12px',
                                                                                    height: '12px',
                                                                                    backgroundColor: '#065c88',
                                                                                    borderRadius: '50%',
                                                                                    transform: 'translate(-50%, -50%)',
                                                                                    opacity: checkedItems[idWorkOrder] ? 1 : 0, 
                                                                                    transition: 'opacity 0.2s'
                                                                                }}></span>
                                                                                </label>
                                                                            </td>
                                                                            <td  className={isChecked ? 'selected-invoice'  : isCheckedPatient ? "selected-invoice-patient": ''}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`checkbox-${idWorkOrderP}`} 
                                                                                    checked={!!checkedItemsPatient[idWorkOrderP]}
                                                                                    onChange={() => handleCheckboxChangePatient(idWorkOrderP)} 
                                                                                    style={{ display: 'none' }}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`checkbox-${idWorkOrderP}`}
                                                                                    style={{
                                                                                    display: 'inline-block',
                                                                                    width: '20px',
                                                                                    height: '20px',
                                                                                    borderRadius: '50%',
                                                                                    border: `2px solid #330005`,
                                                                                    backgroundColor: 'transparent',
                                                                                    cursor: 'pointer',
                                                                                    position: 'relative',
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '50%',
                                                                                        left: '50%',
                                                                                        width: '12px',
                                                                                        height: '12px',
                                                                                        backgroundColor: '#99000f',
                                                                                        borderRadius: '50%',
                                                                                        transform: 'translate(-50%, -50%)',
                                                                                        opacity: checkedItemsPatient[idWorkOrderP] ? 1 : 0,
                                                                                        transition: 'opacity 0.2s',
                                                                                    }}
                                                                                    ></span>
                                                                                </label>
                                                                                </td>

                                                                        </tr>
                                                                    })
                                                                }
                                                                {
                                                                    !isLoadingWorkOrders && filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && formInvoice.type_visualization === TypeVIsualizationEnum.TEST && formInvoice.viewGroup?.length > 0 && formInvoice?.viewGroup?.map((order, index) => {
                                                                        return <tr key={`${index}_${order.id_profile !== null ? order.id_profile : order.id_exam }`} className='text-center f-w-600'>
                                                                            <td>
                                                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/flask_laboratory.svg")} style={{ width: "30px", height: "30px" }} />
                                                                            </td>
                                                                            <td>{ order?.quantity ?? 1}</td>
                                                                            <td>{order?.class_product_code_service}</td>
                                                                            <td>
                                                                                <span>{order?.code_unit}</span> <br />
                                                                                <span>{order?.name_unit}</span>
                                                                            </td>
                                                                            <td>{order?.identification_code}</td>
                                                                            <td>
                                                                                {order?.name_test}                                                                            
                                                                            </td>
                                                                            <td>$ {FormatAmount(order?.price ?? 0, 6)}</td>
                                                                            <td>$ {order?.total_discount}</td>
                                                                            <td className='bg-info'>$ {FormatAmount(order?.import ?? 0, 6)}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                {
                                                                    !isLoadingWorkOrders && filterTypeReceptor !== ReceptorInvoice.GENERAL_PUBLIC && formInvoice.type_visualization === TypeVIsualizationEnum.GLOBAL && formInvoice.listOrders?.length > 0 && <tr className='text-center f-w-500 text-xs'>
                                                                        <td>
                                                                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/flask_laboratory.svg")} style={{ width: "30px", height: "30px" }} />
                                                                        </td>
                                                                        <td>{ 1}</td>
                                                                        <td>{formInvoice?.viewGeneral?.productCode ?? ''}</td>
                                                                        <td className='text-sm'>
                                                                            <div className='d-inline-block'>
                                                                                <span>{formInvoice?.viewGeneral?.unitCode ?? ''}</span>
                                                                                <span>{formInvoice?.viewGeneral?.nameUnit ?? ''}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>{formInvoice?.viewGeneral?.numberIdentification ?? ''}</td>
                                                                        <td>
                                                                            <Input size={'sm'} value={formInvoice?.global_description ?? ''} name='global_description' onChange={(e) => handleChangeInput(e.target.value, 'global_description') } />                                                                     
                                                                        </td>
                                                                        <td>$ {FormatAmount(formInvoice?.viewGeneral?.unitPrice ?? 0, 6)}</td>
                                                                        <td>$ {formInvoice?.viewGeneral?.discount ?? 0.0}</td>
                                                                        <td className='bg-info'>$ {FormatAmount(formInvoice?.viewGeneral?.import ?? 0, 6)}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    !isLoadingWorkOrders && filterTypeReceptor === ReceptorInvoice.GENERAL_PUBLIC && formInvoice.listOrders?.length > 0 && formInvoice?.listOrders?.map((order, index) => {

                                                                        return !order?.is_deleted && <tr key={`${index}_${order.id_work_order }`} className='text-center f-w-600'>
                                                                            <td>
                                                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/flask_laboratory.svg")} style={{ width: "30px", height: "30px" }} />
                                                                            </td>
                                                                            <td>{1}</td>
                                                                            <td>{order?.product_code_service}</td>
                                                                            <td>
                                                                                <span>{order?.code_unit}</span> <br />
                                                                                <span>{order?.name_unit}</span>
                                                                            </td>
                                                                            <td>{order?.nim}</td>
                                                                            <td>
                                                                                { formInvoice?.include_sells ? 'VENTAS' : ( formInvoice?.include_detail_period ? `ANALISIS REALIZADOS DEL ${filterDateRanges.startDate} AL ${filterDateRanges.endDate}` : order?.name_patient ?? '' ) }
                                                                            </td>
                                                                            <td>$ {FormatAmount(order?.price ?? 0, 6)}</td>
                                                                            <td>$ {order?.total_discount}</td>
                                                                            <td className='bg-info'>$ {FormatAmount(order?.import ?? 0, 6)}</td>
                                                                            <td>
                                                                                <Button size='xs' color='danger' onClick={() => handleDeleteTest(
                                                                                    order?.id_exam ?? null, 
                                                                                    order?.id_profile ?? null,
                                                                                    order?.id_work_order_exam ?? null,
                                                                                    order?.id_work_order_profile ?? null,
                                                                                    order?.id_work_order ?? null,
                                                                                    true

                                                                                )}>
                                                                                    <i className='icofont icofont-trash'></i>
                                                                                </Button>
                                                                            </td>
                                                                            <td>
                                                                        </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr rowSpan={3} className='text-center f-16'>
                                                                    <td rowSpan={8} colSpan={6}>
                                                                        <Badge className='f-12 badgeInvoice' style={{ width: '100%' }}>Comentarios de la remisión</Badge>
                                                                        <Input type='textarea' name='comments' value={formInvoice.comments} onChange={(e) => handleChangeInput(e.target.value, 'comments')} className='form-control form-control-sm input-air-primary' rows="5" />
                                                                    </td>
                                                                    <td colSpan={2} className='f-w-600'>Subtotal</td>
                                                                    <td colSpan={2} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.subtotal, 2)} </td>
                                                                </tr>
                                                                <tr className='text-center f-16'>
                                                                    <td colSpan={2} className='f-w-600 '>Descuentos</td>
                                                                    <td colSpan={2} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.discounts, 2)}</td>
                                                                </tr>
                                                                <tr className='text-center f-16'>
                                                                    <td colSpan={2} className='f-w-600'>IVA (16%)</td>
                                                                    <td colSpan={2} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.tax, 2)}</td>
                                                                </tr>
                                                                <tr className='text-center f-16'>
                                                                    <td colSpan={2} className='f-w-600'>Ret. I.S.R</td>
                                                                    <td colSpan={2} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.retentions, 2)}</td>
                                                                </tr>
                                                                <tr className='text-center f-16'>
                                                                    <td colSpan={2} className='f-w-600'>Total</td>
                                                                    <td colSpan={2} className='f-w-600 bg-dark'>$ {FormatNumbers(optionsDue.total, 2)}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }       
            </Container>
            {
                showUpdaetFiscalInformationClient && <ModalCompany
                    {
                        ...{
                            modal: showUpdaetFiscalInformationClient,
                            toggle: handleToggleModal,
                            modalTitle: "Actualizar información fiscal",
                            method: "UpdateBussinessName",
                            id_tax_regime_business_name: formCompanyFiscalInformation.tax_regime_id_client,
                            business_name: formCompanyFiscalInformation.business_name_client,
                            rfc_business_name: formCompanyFiscalInformation.rfc_client,
                            cp_business_name: formCompanyFiscalInformation.cp_client,
                            email_business_name: formCompanyFiscalInformation.email_client,
                            tax_regime_business_name: "",
                            type_person_business_name: formCompanyFiscalInformation.type_person_client === TypePersonInvoiceEnum.FISICA ? 1 : 2,
                            listTaxRegime: listTaxRegimes,
                            handleInputChange: handleChangeInputEditClient,
                            saveUpdateBusinessName: saveUpdateClientInformation,
                            validationsBusinessName,
                        }
                    }
                />
            }
            {
                showUpdateFiscalInformationPatient && <ModalPatient
                    {
                        ...{
                            modal: showUpdateFiscalInformationPatient,
                            toggle: handleToggleModal,
                            modalTitle: 'Actualizar información fiscal',
                            method: 'updateBusinessNamePatient',
                            id_tax_regime: formPatientFiscalInformation.tax_regime_id_patient,
                            type_person: formPatientFiscalInformation.type_person_patient === TypePersonInvoiceEnum.FISICA ? 1 : 2,
                            cp: formPatientFiscalInformation.cp_patient, tax_regime_patient: formPatientFiscalInformation.code_tax_regime,
                            rfc: formPatientFiscalInformation.rfc_patient, email:  formPatientFiscalInformation.email_patient,
                            business_name: formPatientFiscalInformation.business_name_patient,
                            listTaxRegime: listTaxRegimes, 
                            handleInputChange: handleChangeInputEditPatient,
                            validationsPatient,
                            saveUpdate: saveUpdateFiscalInformation
                        }
                    }
                />
            }
            {
                showExtraPatient && <ModalAddExtraPatient
                    {
                        ...{
                            searchPatientExtra: searchExtraPatient,
                            searchPatientValue: searchValueInput,
                            modalExtraPatients: showExtraPatient,
                            toggleModalExtraPatients: handleToggleModal,
                            modalTitle: "Nuevo paciente",
                            handleChangeExtraPatient: handleChangeAddNewWorkOrder,
                            listTempExtraPatients: listWorkOrdersTemp,
                            loadingExtraPatients: false,
                            handleAddExtraPatients: handleConfirmExtraPatients
                        }
                    }
                />
            }
            {
                showPreviewInvoice && <ModalPreviewInvoice
                    {
                        ...{
                            loadingPreview, showPreviewInvoice, handlePreviewInvoice,
                            formInvoice,
                            filterDateRanges,
                            optionsDue
                        }
                    }
                />
            }
            {
                showPreviewInvoicesGlobal && <ModalPreviewInvoiceGlobal
                    {
                        ...{
                            loadingPreviewInvoices, showPreviewInvoicesGlobal, 
                            handlePreviewGlobalInvoices,handleCancelGlobal,
                            saveInvoicesGlobal,loadingGlobal
                           
                        }
                    }
                />
            }
            {
                showLoading && <ModalLoading
                    showLoding={showLoading}
                    handleLoading={handleLoadingPreInvoice}
                />
            }
              {
                showPreviewInvoicesPatient && <ModalPreviewInvoicePatient
                    {
                        ...{
                            loadingPreviewInvoicesP, showPreviewInvoicesPatient, 
                            handlePreviewPatientGlobal,handleCancelPatient,
                            saveInvoicesPatient,loadingGlobalPatient
                           
                        }
                    }
                />
            }
        </Fragment>
    )
}
