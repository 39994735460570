import { useEffect, useState } from 'react'
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { ConfigServer } from '../../data/config';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as XLSX from 'xlsx';



export const useReportMicrositio = () => {

    //#region LOADINGS
    const [loadingTotalIngress, setLoadingTotalIngress] = useState(false);
    const [loadingOutsideBalance, setLoadingOutsideBalance] = useState(false);
    const [loadingIngressInvoiced, setLoadingIngressInvoiced] = useState(false);
    const [loadingIngressPending, setLoadingIngressPending] = useState(false);
    const [loadingDiscount, setLoadingDiscount] = useState(false);
    const [loadingClients, setLoadingClients] = useState(false);
    const [loadingExams, setLoadingExams] = useState(false);
    const [loadingProfiles, setLoadingProfiles] = useState(false);
    const [loadingTableClients, setLoadingTableClients] = useState(false);

    const [viewBeforeMonth, setViewBeforeMonth] = useState(false);
    const [viewCurrentMonth, setViewCurrentMonth] = useState(true);
    const [viewAfterMonth, setViewAfterMonth] = useState(false);

    const [loadingExpiredWallet, setLoadingExpiredWallet] = useState(false);
    //#endregion


    //#region ELEMENTS DASHBOARD
    const [lblTotalIngress, setLblTotalIngress] = useState(0.0);
    const [lblTotalPercentageIngress, setLblPercentageIngress] = useState(0.0);
    const [lblTotalPaidIngress, setLblPaidIngress] = useState(0.0);


    const [lblOutSideBalance, setLblOutSideBalance] = useState(0.0);
    const [lblIngreessInvoice, setLblIngreessInvoice] = useState(0.0);
    const [lblIngressPendiing, setLblIngressPendiing] = useState(0.0);
    const [lblPercentageInvoiced, setLblPercentageInvoiced] = useState(0);
    const [lblTotalPatient, setLblTotalPatient] = useState(0);

    const [lblPendingPercentageInvoiced, setLblPendingPercentageInvoiced] = useState(0);
    const [lblTotalOrders, setLblTotalOrders] = useState(0);
    const [lblDiscount, setLblDiscount] = useState(0.0);
    const [lblTotalClients, setLblTotalClients] = useState(0);
    const [lblTotalExams, setLblTotalExams] = useState(0);
    const [lblTotalProfiles, setLblTotalProfiles] = useState(0);
    const [lblTotalCash, setLblTotalCash] = useState(0);
    const [lblTotalCredit, setLblTotalCredit] = useState(0);

    const [lblCurrentTotalInvoices, setLblCurrentTotalInvoices] = useState(0);
    const [lblCurrentPaymentMonth, setLblCurrentPaymentMonth] = useState(0);
    const [lblTotalInvoicesNextMonth, setLblTotalInvoicesNextMonth] = useState(0);
    const [lblCurrentPaidInvoices, setLblCurrentPaidInvoices] = useState(0);
    const [lblPercentageCompliance, setLblPercentageCompliance] = useState(0);
    const [lbPercentageInvoice, setLblPercentageInvoice] = useState(0.0);


    const [lblCurrentTotalBilling, setLblCurrentTotalBilling] = useState(0);
    const [lblCurrentPaymentBilling, setLblCurrentPaymentBilling] = useState(0);
    const [lblCurrentPendingPaymentBilling, setLblCurrentPendingPaymentBilling] = useState(0);
   
    const [lblBeforeTotalBilling, setLblBeforeTotalBilling] = useState(0);
    const [lblBeforePaymentBilling, setLblBeforePaymentBilling] = useState(0);
    const [lblBeforePendingPaymentBilling, setLblBeforePendingPaymentBilling] = useState(0);
    
    const [lblAfterTotalBilling, setLblAfterTotalBilling] = useState(0);
    const [lblAfterPaymentBilling, setLblAfeterPaymentBilling] = useState(0);
    const [lblAfterPendingPaymentBilling, setLblAfterPendingPaymentBilling] = useState(0);

    const [detailTransactionsByClients, setDetailTransactionsByClients] = useState([]);
    const [listDetailTransactionsByClients, setListDetailTransactionsByClients] = useState([]);
    const [listIndicatorsDetail, setListIndicatorsDetail] = useState([]);

    const [detailSeriesExpiredWallet, setDetailSeriesExpiredWallet] = useState([]);
    //#endregion

    // * FILTERS
    const [filterCredit, setFilterCredit] = useState(false);
    const [filterAccount, setFilterAccount] = useState(false);
    const [filterPayment, setFilterPayment] = useState(false);
    const [filterPendingPayment, setFilterPendingPayment] = useState(false);
    const [filterInvoiced, setFilterInvoiced] = useState(false);
    const [filterPendigInvoiced, setFilterPendigInvoiced] = useState(false);
    const [filterPreInvoice, setFilterPreInvoice] = useState(false);
    const [filterPpd, setFilterPpd] = useState(false);
    const [filterPue, setFilterPue] = useState(false);
    const [filterOutValidity, setFilterOutValidity] = useState(true);
    const [filterPaid, setFilterPaid] = useState(false);
    const [filterCurrent, setFilterCurrent] = useState(false);
    const [filterViewAll, setFilterViewAll] = useState(false);

    // ? FILTER PAY METHODS
    const [filterAmount, setFilterAmount] = useState(false);
    const [filterAmountCredit, setFilterAmountCredit] = useState(false);
    const [filterAmountDebit, setFilterAmountDebit] = useState(false);
    const [filterAmountTcredit, setFilterAmountTcredit] = useState(false);
    const [filterAmountTrasferencia, setFilterAmountTrasferencia] = useState(false);

    const [TotalPaidPatient, setTotalPaidPatient] = useState(0);

    //#region FORM DATE
    const [dateFilter, setDateFilter] = useState("");
    const [createSweet] = useSweetAlert();
    //#endregion
    const id_company = localStorage.getItem('id_company');
    console.log("🚀 ~ useReportMicrositio ~ id_company:", id_company)

            
    // *Tabs
    const userType = localStorage.getItem('userType')
    console.log("🚀 ~ userType:", userType)
    const [activeIndex, setActiveIndex] = useState(0);
    console.log("🚀 ~ activeIndex:", activeIndex)
    const items = [
        { label: "Resultados", icon: "pi pi-check-circle" },
        ...(userType !== "2" ? [{ label: "Reporte de Cuentas", icon: "pi pi-money-bill" }] : [])
    ];
    
    // *METHODS
    const GetReportCxC = async (dateStart, dateEnd) => 
    {
        handleUpdateLoadings(true);
        // const response = await fetch(`/api/Microsite/GetCompany?id_company=${id_company}`, {

        const Response = await GlobalRequest("GET", `Reports/GetReportCxC/${dateStart}/${dateEnd}?id_company=${id_company}`, "Reporte");

        console.log("🚀 ~ useReportMicrositio ~ id_company:", id_company)
        if (Response !== null) {
            setLblTotalIngress(number_format((Response?.totalIngress ?? 0), 2));
            setLblOutSideBalance(number_format((Response?.totalPending ?? 0), 2));
            setLblPercentageIngress(number_format((Response?.totalPercentage ?? 0), 2));
            setLblPaidIngress(number_format((Response?.totalPaids ?? 0), 2));

            setLblDiscount((Response?.totalDiscounts ?? 0));
            setLblTotalClients(Response?.totalClients ?? 0);
            setLblTotalExams(Response?.totalExams ?? 0);
            setLblTotalProfiles(Response?.totalProfiles ?? 0);
            setLblTotalCash(Response?.totalCash ?? 0);
            setLblTotalCredit(Response?.totalCredit ?? 0);
            setDetailTransactionsByClients(Response?.detailTransactionsByClients);
            setListDetailTransactionsByClients(Response?.detailWorkOrderByClients);

            setLblIngreessInvoice(Response?.detailTransactionsPercentageBilling?.totalInvoice ?? 0);
            setLblIngressPendiing(Response?.detailTransactionsPercentageBilling?.totalPendingInvoice ?? 0);
            setLblPercentageInvoiced(Response?.detailTransactionsPercentageBilling?.billingPorcentageInvoiced ?? 0);
            setLblTotalPatient(Response?.detailTransactionsPercentageBilling?.totalPatient?? 0);


            setLblPendingPercentageInvoiced(Response?.detailTransactionsPercentageBilling?.billingPorcentagePendingInvoiced ?? 0);
            setLblTotalOrders(Response?.detailTransactionsPercentageBilling?.totalOrders ?? 0);
        }
        else {
            if (activeIndex === 1) { 
                createSweet("warning", "warning", "Opss..", "Sin información detallada en el rango de fecha establecido");
            }        }

        handleUpdateLoadings(false);
    }

    // const GetReportIndicators = async (dateStart, dateEnd, _id_client = null) => 
    // {

    //     handleUpdateLoadings(true);

    //     const response = await GlobalRequest('GET', `Reports/CxcIndicatorReport/${dateStart}/${dateEnd}${_id_client == null ? "" : "?id_company="+_id_client}`, "Indicadores");

    //     if (response !== null) {

    //         setLblCurrentTotalInvoices(response?.currentTotalInvoices ?? 0);
    //         setLblCurrentPaymentMonth(response?.currentPaymentMonth ?? 0);
    //         setLblTotalInvoicesNextMonth(response?.totalInvoicesPaymentNextMonth ?? 0);
    //         setLblCurrentPaidInvoices(response?.currentPaidInvoices ?? 0);
    //         setLblPercentageCompliance(response?.porcentageSuccess ?? 0);

    //         setLblCurrentTotalBilling(response?.currentTotalBilling ?? 0);
    //         setLblCurrentPaymentBilling(response?.currentPaymentBilling ?? 0);
    //         setLblCurrentPendingPaymentBilling(response?.currentPendingPaymentBilling ?? 0);

    //         setLblBeforeTotalBilling(response?.beforeTotalBilling ?? 0);
    //         setLblBeforePaymentBilling(response?.beforePaymentBilling ?? 0);
    //         setLblBeforePendingPaymentBilling(response?.beforePendingPaymentBilling ?? 0);

    //         setLblAfterTotalBilling(response?.afterTotalBilling ?? 0);
    //         setLblAfeterPaymentBilling(response?.afterPaymentBilling ?? 0);
    //         setLblAfterPendingPaymentBilling(response?.afterPendingPaymentBilling ?? 0);

    //         setListIndicatorsDetail(response?.reportIndicatorDetailInvoices ?? []);
    //         setLblPercentageInvoice(response?.percentage ?? 0)
            
    //     }
    //     else {
    //         createSweet("warning", "warning", "Opss..", "Sin información de indicadores");
    //     }

    //     handleUpdateLoadings(false);
    // }

    // const GetExpiredWallet = async (_id_client = null) => {
    //     setLoadingExpiredWallet(true);

    //     const response = await GlobalRequest('GET', `Reports/CxcExpiredWallet${_id_client === null ? "" : "?id_company="+_id_client}`, "Cartera vencida");
    //     if (response !== null) {
    //         setDetailSeriesExpiredWallet(response?.series ?? []);
    //     }
    //     else{
    //         createSweet("warning", "warning", "Opss..", "Sin información de cartera vencida");
    //     }

    //     setLoadingExpiredWallet(false);
    // }

    // *USE EFFECT

    useEffect(async () => {
        
        let _Year = new Date().getFullYear();
        let _Month = (new Date().getMonth() + 1);
        _Month = _Month < 10 ? `0${_Month}` : _Month;
        
        let startDate = `${_Year}-${_Month}-01`;
        let lastDate = new Date(_Year, _Month, 0);

        let lastDateYear = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate()); 

        // await GetReportIndicators(startDate, lastDateYear);
        await GetReportCxC(startDate, lastDateYear);
        // await GetExpiredWallet();
        

    }, []);

    // * HANDLE METHODS
    
    const handleUpdateLoadings = (updateStatus) => 
    {
        setLoadingTotalIngress(updateStatus);
        setLoadingOutsideBalance(updateStatus);
        setLoadingIngressInvoiced(updateStatus);
        setLoadingIngressPending(updateStatus);
        setLoadingDiscount(updateStatus);
        setLoadingClients(updateStatus);
        setLoadingExams(updateStatus);
        setLoadingProfiles(updateStatus);
        setLoadingTableClients(updateStatus);
    }

    const handleFilterByClient = async (_id_client) => 
    {
        let dateStart = "";
        let dateEnd = "";

        if (dateFilter !== "") {
            dateStart = dateFilter + "-01";

        let _Year = dateStart.split("-")[0];  
   
        let _Month = (new Date(dateStart+"T00:00:00").getMonth() + 1);

        _Month = _Month < 10 ? `0${_Month}` : _Month;
            
        let lastDate = new Date(_Year, _Month, 0);

        dateEnd = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate());  

        }
        else {
            console.log("gatos2");

            let _Year = new Date().getFullYear();
            let _Month = (new Date().getMonth() + 1);
            _Month = _Month < 10 ? `0${_Month}` : _Month;
            
            dateStart = `${_Year}-${_Month}-01`;
            let lastDate = new Date(_Year, _Month, 0);

            dateEnd = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate()); 
        }

        await GetReportCxC(dateStart, dateEnd, _id_client);
        // await GetReportIndicators(dateStart, dateEnd,_id_client);
        // await GetExpiredWallet(_id_client);

        

    }

    const handleFilterDate = async() => 
    {
        if (dateFilter !== "") {
            let dateIni = dateFilter + "-01";

            let _Year = new Date(dateIni).getUTCFullYear();
            let _Month = (new Date(dateIni+"T00:00:00").getMonth() + 1);
            _Month = _Month < 10 ? `0${_Month}` : _Month;
            
            let lastDate = new Date(_Year, _Month, 0);

            let lastDateYear = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate());           
            
            await GetReportCxC(dateIni, lastDateYear);
            // await GetReportIndicators(dateIni, lastDateYear);
            // await GetExpiredWallet();

        }        
    }

    const handleCleanFilterByClient = async () => {
        let dateStart = "";
        let dateEnd = "";

        let _Year = new Date().getFullYear();
        let _Month = (new Date().getMonth() + 1);
        _Month = _Month < 10 ? `0${_Month}` : _Month;
        
        dateStart = `${_Year}-${_Month}-01`;
        let lastDate = new Date(_Year, _Month, 0);

        dateEnd = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate());  

        await GetReportCxC(dateStart, dateEnd);
        // await GetReportIndicators(dateStart, dateEnd);
        // await GetExpiredWallet();


    }

    const handleViewTransactions = (before, current, after) => {
        setViewBeforeMonth(before);
        setViewCurrentMonth(current);
        setViewAfterMonth(after);
    }

    const handleFilterTransactions = (all, outValidity, current, paid) => {
        setFilterViewAll(all);
        setFilterOutValidity(outValidity);
        setFilterCurrent(current);
        setFilterPaid(paid);
    }


    const MakeExcelTransactionMontly = (viewBefore, viewCurrent, all = false, outValidity = false, payment = false, current) => {
        let _report = [];

        listIndicatorsDetail?.length > 0 && 
        listIndicatorsDetail.filter(_invoice => {
            if (viewBefore) {
                if (_invoice?.beforeMonth) {
                    if (outValidity && _invoice?.status === "VENCIDA") {
                        return _invoice;
                    }
                    else if (payment && _invoice?.status === "PAGADA") {
                        return _invoice;
                    }
                    else if (current && _invoice?.status === "VIGENTE") {
                        return _invoice;
                    }
                    else if (all) {
                        return _invoice;
                    }
                }
            }
            else if (viewCurrent) {
                if (_invoice?.currentMonth) {
                    if (outValidity && _invoice?.status == "VENCIDA") {
                        return _invoice;
                    }
                    else if (payment && _invoice?.status == "PAGADA") {
                        return _invoice;
                    }
                    else if (current && _invoice?.status == "VIGENTE") {
                        return _invoice;
                    }
                    else if (all) {
                        return _invoice;
                    }
                }
            }
        })
        .map((_invoice, _index) => {
            _report.push({
                "#": (_index + 1),
                "Fecha_emisión": _invoice?.dateStampInvoice,
                "N_factura":_invoice?.serie ?? "" + " " + _invoice?.folio ?? "",
                "Cliente": _invoice?.nameClient,
                "Monto_facturado": number_format(_invoice?.totalInvoice, 2),
                "Dias_de_credito": _invoice?.creditDays,
                "F_de_Vencimiento": _invoice?.validityDate,
                "Estatus": _invoice?.status,
                "F_Pago": _invoice?.paymentDate ?? "",
                "Complemento": _invoice?.idComplement ?? ""
            })
        });

        const worksheet = XLSX.utils.json_to_sheet(_report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'indicadores_facturacion.xlsx';

        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }


    
    

    // const ChartAgePayable = {        
    //     series: detailSeriesExpiredWallet,
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             height: 150,
    //             stacked: true,
    //             toolbar: {
    //                 show: false
    //             },
    //             width: '100%',
    //             stackType: '100%'
    //         },
    //         colors: ['#69d83a','#32a700', '#00A878', '#FFFB46', '#f75e25', '#e01410'],
    //         plotOptions: {
    //             bar: {
    //                 horizontal: true,
    //                 vertical: false,
    //             },
    //         },
    //         grid: {
    //             borderColor: ''
    //         },
    //         stroke: {
    //             width: 1,
    //             colors: ['#fff']
    //         },    
    //         xaxis: {
    //             categories: [" "],
    //             labels: {
    //                 show: false
    //             }
    //         },
    //         tooltip: {
    //             x: {
    //                 formatter: function (val) {
    //                     return "Días por Vencimiento: " + val
    //                 }
    //             },
    //             y: {
    //                 formatter: function (val) {
    //                     return "$ " + val;
    //                 }
    //             },
    //             custom: function({ seriesIndex, dataPointIndex, w }) {
    //                 const folios = w.config.series[seriesIndex]?.folio || [];
    //                 return `
    //                     <div style="padding: 30px; font-size: 12px;">
    //                     <label className='txt-secondary f-w-500' >Facturas Pendientes </label> <br>
    //                         <strong>Monto: </strong>$${w.config.series[seriesIndex].data[dataPointIndex]}<br>
    //                         <strong>Folio(s): </strong>${folios.join(', ') || 'No hay folios'}
    //                     </div>
    //                 `;
    //             }
    //         },
    //     },
    // };
    





    // const chartOptions = {
    //     chart: {
    //         type: "pie", 
    //     },
    //     title: {
    //         text: "Monto de créditos en empresas", 
    //     },
    //     colors: ["#33a9eb", "#6c757d"],  
    //     plotOptions: {
    //         pie: {
    //             innerSize: "50%",  
    //             dataLabels: {
    //                 enabled: true,
    //                 format: "{point.percentage:.1f}%",  // Mostrar el porcentaje con un decimal
    //             },
    //         },
    //     },
    //     series: [
    //         {
    //             name: "Monto",  
    //             colorByPoint: true,
    //             data: [
    //                 { name: "Crédito", y: lblTotalCredit },  
    //                 { name: "Contado", y: lblTotalCash }, 
    //             ],
    //         },
    //     ],
    //     tooltip: {
    //         pointFormat: "{point.name}: {point.percentage:.1f}%", // Mostrar solo el porcentaje en el tooltip
    //     },
    //     legend: {
    //         show: true,
    //         position: 'bottom',  
    //     },
    //     responsive: [
    //         {
    //             breakpoint: 480,
    //             options: {
    //                 chart: {
    //                     width: 200,  
    //                 },
    //                 legend: {
    //                     position: 'bottom', 
    //                 },
    //             },
    //         },
    //     ],
    // };
    

   
      
  

    const ChartPaymentConditionsTable = {
        options: {
            chart: {
                type: 'donut',
                width: 40,
                height: 40,
                sparkline: {
                    enabled: true
                }
            },
            labels: ["Crédito", "Contado", "Descuento"],
            stroke: {
                width: 1
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
            }
        },
    }
    

    //*DOWNLOAD REPORTS
    const DownloadReportCxc = async () => {

        //!FILTER DATE REPORT CXC
        let dateStart = "";
        let dateEnd = "";

        if (dateFilter !== "") {
            dateStart = dateFilter + "-01";

            let _Year = dateStart.split("-")[0];  

            let _Month = (new Date(dateStart+"T00:00:00").getMonth() + 1);
            _Month = _Month < 10 ? `0${_Month}` : _Month;
            
            let lastDate = new Date(_Year, _Month, 0);

            dateEnd = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate());  
        }
        else {
            let _Year = new Date().getFullYear();
            let _Month = (new Date().getMonth() + 1);
            _Month = _Month < 10 ? `0${_Month}` : _Month;
            
            dateStart = `${_Year}-${_Month}-01`;
            let lastDate = new Date(_Year, _Month, 0);

            dateEnd = _Year+"-"+_Month+"-"+(lastDate.getDate() < 10 ? `0${lastDate.getDate()}` : lastDate.getDate()); 
        }

        // let _token = localStorage.getItem("token");

        await fetch(`/api/Reports/DownloadReportCxC/${dateStart}/${dateEnd}?id_company=${id_company}`, {
            method: 'GET',
            headers: new Headers({
                // "Authorization": "Bearer " ,
                "Content-Type": "application/vnd.ms-excel"
            })
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Reporte_De_Facturas.xlsx")
                document.body.appendChild(link)
                    ;
                link.style.display = 'none';
                link.click();

            })

    }


    // * GLOBAL REQUEST
    const GlobalRequest = async (method, methodUrl, nameMethod, body = null) =>
    {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else {
            return null;
        }
    }

    // * FORMAT NUMBERS
    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }


    return {
        loadingTotalIngress, loadingOutsideBalance, loadingIngressInvoiced,
        loadingIngressPending, loadingDiscount, loadingClients,
        loadingExams, loadingProfiles, loadingTableClients, loadingExpiredWallet,

        lblTotalIngress, lblOutSideBalance, lblIngreessInvoice, lblIngressPendiing,
        lblDiscount, lblTotalClients, lblTotalExams, lblTotalProfiles, 
        lblCurrentTotalInvoices, lblCurrentPaymentMonth, lblTotalInvoicesNextMonth,
        lblCurrentPaidInvoices, lblPercentageCompliance,
        lblCurrentTotalBilling, lblCurrentPaymentBilling, lblCurrentPendingPaymentBilling,
        lblBeforeTotalBilling, lblBeforePaymentBilling, lblBeforePendingPaymentBilling,
        lblAfterTotalBilling, lblAfterPaymentBilling, lblAfterPendingPaymentBilling,

        viewBeforeMonth, viewCurrentMonth, viewAfterMonth,

        detailTransactionsByClients, listDetailTransactionsByClients, listIndicatorsDetail,

        lblPercentageInvoiced, lblPendingPercentageInvoiced, lblTotalOrders,

        dateFilter, setDateFilter,

        //CHARTS
        ChartPaymentConditionsTable,

        handleFilterByClient, handleFilterDate, number_format,
        handleCleanFilterByClient, handleViewTransactions,
        handleFilterTransactions, 

        MakeExcelTransactionMontly,

        // * FILTERS
        filterCredit, setFilterCredit,
        filterAccount, setFilterAccount,
        filterPayment, setFilterPayment,
        filterPendingPayment, setFilterPendingPayment,
        filterInvoiced, setFilterInvoiced,
        filterPendigInvoiced, setFilterPendigInvoiced,
        filterPreInvoice, setFilterPreInvoice,
        filterPpd, setFilterPpd,
        filterPue, setFilterPue,
        filterOutValidity, 
        filterCurrent, 
        filterPaid, 
        filterViewAll, 

        //* DOWNLOAD REPORT CXC
        DownloadReportCxc,

        // ? FILTER PAYMETHODS
        filterAmountCredit, setFilterAmountCredit,
        filterAmountDebit, setFilterAmountDebit,
        filterAmountTcredit, setFilterAmountTcredit,
        filterAmountTrasferencia, setFilterAmountTrasferencia,
        filterAmount, setFilterAmount,
        // chartOptions,

        lblTotalPercentageIngress,lblTotalPaidIngress,lblTotalPatient,lbPercentageInvoice,detailSeriesExpiredWallet, id_company,

        // ? Tabs
        activeIndex, setActiveIndex, items, userType
    }
}
