import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Table, CardHeader, Badge, TabContent, TabPane, Nav, NavLink, NavItem, Button, Input, Label, Pagination } from 'reactstrap';
import { useMicrositePatients } from '../../../hooks/micrositioCompany/useMicrositePatients';
import Chart from 'react-apexcharts'
import { useState } from 'react';
import { ComponentBalanceCompanyAgreement } from './ComponentBalanceCompanyAgreement';
import { ComponentPayments } from './ComponentPayments';
import './styleMicrositio.css';
import { ComponentInformationAgreement } from './ComponentInformationAgreement';
import { ModalEdit } from './ModalEdit';
import { TableStriped } from '../../../components/tables/TableStriped'
import { ModalPreview } from './ModalPreview';
import Select from 'react-select';
import { nameCompany } from '../../../constant';
import { TabMenu } from 'primereact/tabmenu';
import { color } from 'highcharts';
// import { IndexReportsMicrositio } from '../../../views/reports/cxc/IndexReportsMicrositio';
import { useReportMicrositio } from '../../../hooks/reports/useReportMicrositio';
const Sampl = (props) => {


    const {
        patients,
        iNim, iDateFrom, iDate_to, handleInputChangeFilters, handleFilters, handleCleanFilter,
        //RETURN PAGINATION
        goNextPage, goPreviousPage, totalPages, actualPage, goToPage,
        prevPage, nextPage, totalRecords, resultCount, key,

        //Modal preview
        modalPreview, togglePreview, handlePreview, typeModall, loadingPdf, previewWorkOrder, workMedicalOrderPDF,

        //Download pdf
        printWorkOrder,

        statusOrder, mSelectStatus, handleSelectValues,

        //Descargas
        numberDownloads,

        // Company
        companyName, requirements,
    } = useMicrositePatients();


    const {
        //Reports Microsite
        loadingTableClients, listDetailTransactionsByClients, dateFilter, setDateFilter, handleFilterDate, number_format,
        handleCleanFilterByClient,
        // * FILTERS
        filterCredit, setFilterCredit,
        filterAccount, setFilterAccount,
        filterPayment, setFilterPayment,
        filterPendingPayment, setFilterPendingPayment,
        filterInvoiced, setFilterInvoiced,
        filterPendigInvoiced, setFilterPendigInvoiced,
        filterPreInvoice, setFilterPreInvoice,
        filterPpd, setFilterPpd,
        filterPue, setFilterPue,

        // ? FILTER PAYMETHODS
        filterAmountCredit, setFilterAmountCredit,
        filterAmountDebit, setFilterAmountDebit,
        filterAmountTcredit, setFilterAmountTcredit,
        filterAmountTrasferencia, setFilterAmountTrasferencia,
        filterAmount, setFilterAmount,

        lblTotalIngress, loadingTotalIngress, loadingOutsideBalance, lblOutSideBalance,
        lblTotalPaidIngress, loadingClients, loadingDiscount, lblTotalPercentageIngress,
        lblTotalPatient,

        // ? Excel 
        DownloadReportCxc,detailTransactionsByClients, id_company, 

        // ? Tabs
        activeIndex, setActiveIndex, items, userType

    } = useReportMicrositio();
        console.log("🚀 ~ Sampl ~ id_company:", id_company)

    let consecutivo = 1;

    //*Reports Microsite
    const [total, setTotal] = useState(0)


    const calcularTotal = () => {
        const datosFiltrados = listDetailTransactionsByClients.filter(transaction => {
            if (filterCredit && transaction.paymentCondition === "Crédito") return true;
            if (filterAccount && transaction.paymentCondition === "Pago de contado") return true;
            if (filterPayment && transaction.paymentStatus === "Pagado") return true;
            if (filterPendingPayment && transaction.paymentStatus === "Pendiente") return true;
            if (filterInvoiced && transaction.financialStatus === "Facturado") return true;
            if (filterPendigInvoiced && transaction.financialStatus === "Pendiente") return true;
            if (filterPreInvoice && transaction.financialStatus === "Prefactura") return true;
            if (filterPpd && transaction.payMethod === "PPD") return true;
            if (filterPue && transaction.payMethod === "PUE") return true;
            if (filterAmount && transaction.id_Pay_method === 1) return true;
            if (filterAmountCredit && transaction.id_Pay_method === 10) return true;
            if (filterAmountDebit && transaction.id_Pay_method === 4) return true;
            if (filterAmountTcredit && transaction.id_Pay_method === 2) return true;
            if (filterAmountTrasferencia && transaction.id_Pay_method === 8) return true;


            if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue && !filterAmount && !filterAmountCredit && !filterAmountDebit && !filterAmountTcredit && !filterAmountTrasferencia) return true;
            return false;
        });

        const total = datosFiltrados.reduce((suma, item) => {
            const _total = item.totalWorkOrder + item.totalTax || 0;
            return suma + _total;
        }, 0);

        setTotal(total);
    };

    useEffect(() => {
        calcularTotal();
    }, [filterCredit, filterAccount, filterPayment, filterPendingPayment, filterInvoiced, filterPendigInvoiced, filterPreInvoice, filterPpd, filterPue, listDetailTransactionsByClients, filterAmount, filterAmountCredit, filterAmountDebit, filterAmountTcredit, filterAmountTrasferencia]);


    const totalRowPatient = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
            <td ></td>
            <td></td>
            <td ></td>
            <td></td>
            <td style={{ textAlign: "end" }} colSpan={3} className='labeltheadInvoice'><label className='f-12' >Total Pendiente:</label> </td>
            <td colSpan={2} style={{ textAlign: "left" }} className='labeltheadInvoice'><label className='f-12' > ${number_format(total, 2)}</label> </td>
            <td></td>
            <td ></td>
            <td></td>
            <td ></td>
            <td></td>
            <td></td>
        </tr>
    );

    return (
        <Fragment>
            <>
                <br /><br /><br /> <br />
                { userType !== "2" && (
                      <Card sm="12" md="12" lg="12">
                      <CardHeader
                          className="p-3"
                          style={{
                              backgroundColor: "#f0f7ff",
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '10px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                              padding: '15px 20px',
                              marginBottom: '15px',
                              justifyContent: 'space-between',
                          }}
                      >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img
                                  src={require("../../../assets/images/microsite/company.png")}
                                  alt="company"
                                  style={{ width: "35px", marginRight: "10px" }}
                              />
  
                              <p
                                  className="f-w-600 f-14 badge badge-light-primary"
                                  style={{
                                      fontSize: '16px',
                                      color: '#005b96',
                                      fontWeight: '600',
                                      margin: '0',
                                      padding: '8px 12px',
                                      borderRadius: '15px',
                                      boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                                  }}
                              >
                                  {companyName}
                              </p>
                          </div>
  
                          <a
                              href={requirements.split('requirements')[1]}
                              target='_blank'
                              className='f-w-600 f-14 badge badge-light-primary mt-2'
                              style={{
                                  textDecoration: 'none',
                                  fontSize: '16px',
                                  color: '#005b96',
                                  fontWeight: '600',
                                  margin: '0',
                                  padding: '8px 12px',
                                  borderRadius: '15px',
                                  boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                              }}
                          >
                              {requirements}
                          </a>
                      </CardHeader>
                  </Card>

                )}

                
                  
               

                <Container fluid={true} className="p-l-0">
             
                    <TabMenu
                        model={items}
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                        className="p-tabmenu-custom"

                    />

                    <br />
                    {activeIndex === 0 && (
                        <>
                            <Row>
                                {/* Parte de las búsquedas */}
                                <Col sm="3" className='p-r-0' >

                                    <Col sm="12" md="12" lg="12">
                                        <Card>
                                            <CardHeader className='p-3  bg-primary'>
                                                <i className='fa fa-filter fIconFilter f-20 '></i>
                                                <label className='f-14'>Filtros de Busqueda</label>
                                            </CardHeader>
                                            <CardBody className='p-3'>
                                                <Row className='pb-4'>
                                                    <Col sm="12" md="12" lg="12" className='text-center'>
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12">
                                                        <label className='f-w-600 f-12'>Nim o Nombres</label>
                                                        <Input type='text' value={iNim} name="iNim" onChange={handleInputChangeFilters} className='border-1 mr-2' />

                                                    </Col>
                                                    <Col sm="12" md="12" lg="12" className='pt-2'>
                                                        <label className='f-w-600 f-12'>Fecha Inicio</label>
                                                        <Input type='date' value={iDateFrom} name='iDateFrom' onChange={handleInputChangeFilters} className='border-1 mr-2' placeholder='YYYY-MM-DD' />
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12" className='pt-1'>
                                                        <label className='f-w-600 f-12'>Fecha Final</label>
                                                        <Input type='date' value={iDate_to} name="iDate_to" onChange={handleInputChangeFilters} className='border-1 mr-2' placeholder='YYYY-MM-DD' />
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12" className='pt-1'>

                                                    </Col>
                                                    <Col sm="12" md="12" lg="12" className='pt-1'>
                                                        <label className='f-w-600 f-12'>Estatus</label>
                                                        <div className='form-group'>
                                                            <Select
                                                                name="mSelectStatus"
                                                                isClearable={true}
                                                                placeholder="Selecciona un estatus"
                                                                noOptionsMessage="Sin resultados"
                                                                options={statusOrder}
                                                                onChange={(e) => handleSelectValues(e, 'mSelectStatus')}
                                                                value={mSelectStatus}
                                                                className="b-r-9"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12" className='pt-1'>
                                                        <div type="submit" onClick={() => handleFilters()} className='pointer' style={{ color: "#fff", backgroundColor: "#0079C7", height: "35px", textAlign: "center" }}>
                                                            Buscar
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12" className='pt-1'>
                                                        <div type="submit" onClick={() => handleCleanFilter()} className='pointer' style={{ color: "#fff", backgroundColor: "#dc3545", height: "35px", textAlign: "center" }}>
                                                            Limpiar
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Col>


                                {/* Tarjeta de la tabla de pacientes */}
                                <Col sm="9">
                                    <Card className='shadow'>
                                        <CardBody className='pl-2 pr-2' >

                                            {/* Tabla de pacientes */}
                                            {

                                                totalRecords === 0 ?
                                                    <div style={{ textAlign: "-webkit-center" }}>
                                                        <p className='f-w-600 f-14 badge badge-light-primary'>No se encontraron resultados.</p>

                                                        <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "32%" }} alt="" />
                                                    </div>
                                                    :


                                                    <div className="">
                                                        <Table size='xs' className="table-border-vertical ">
                                                            <thead className="theadA">
                                                                <tr>
                                                                    {/* <th className='labelThead' scope="col">#</th> */}
                                                                    <th className='labelThead' scope="col">Nim</th>
                                                                    <th className='labelThead' scope="col">Nombre</th>
                                                                    <th className='labelThead' scope="col">Fecha</th>
                                                                    <th className='labelThead' scope="col">Estatus</th>
                                                                    <th className='labelThead' scope='col'>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {

                                                                    patients.length > 0 && patients.map((_patients, _key) => {
                                                                        return (
                                                                            <tr key={_key} style={{ verticalAlign: "middle" }} >
                                                                                {/* <td>
                                                            {(_key + 1)}
                                                        </td> */}
                                                                                <td>
                                                                                    {_patients.nim}
                                                                                </td>
                                                                                <td>
                                                                                    {_patients.name_patient}
                                                                                </td>
                                                                                <td>
                                                                                    {_patients.fechAndDate}
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        _patients.id_estatus_order === 1 ? "ABIERTA" :
                                                                                            _patients.id_estatus_order === 5 ? "EN PROCESO" :
                                                                                                _patients.id_estatus_order === 6 ? "EN PROCESO" :
                                                                                                    _patients.id_estatus_order === 7 ? "AUTORIZADA" :
                                                                                                        _patients.id_estatus_order === 8 ? "AUTORIZADA" :
                                                                                                            _patients.id_estatus_order === 9 ? "AUTORIZADA" :
                                                                                                                _patients.id_estatus_order === 10 ? "AUTORIZADA" :
                                                                                                                    _patients.id_estatus_order === 13 ? "AUTORIZADA" :
                                                                                                                        _patients.id_estatus_order === 15 ? "AUTORIZADA" :
                                                                                                                            _patients.id_estatus_order === 18 ? "AUTORIZADA" :
                                                                                                                                _patients.id_estatus_order === 20 ? "CANCELADA" : ""
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <Row>
                                                                                        {(_patients.id_estatus_order === 5 || _patients.id_estatus_order === 6 || _patients.id_estatus_order === 7 || _patients.id_estatus_order == 8 || _patients.id_estatus_order === 10
                                                                                            || _patients.id_estatus_order === 13 || _patients.id_estatus_order === 15 || _patients.id_estatus_order === 18) ? (
                                                                                            <>
                                                                                                <Col>
                                                                                                    <Row style={{ alignContent: "center", alignItems: "center", position: "center", justifyContent: "center" }}>
                                                                                                        &nbsp;
                                                                                                        <Button color='primary' size="xs" type='button' className='btn btn-pdf' onClick={() => handlePreview(_patients.id_work_order)}>
                                                                                                            <i className="fa fa-eye"></i>
                                                                                                        </Button> &nbsp; &nbsp;
                                                                                                        {_patients.downloads > 0 ?
                                                                                                            <Button color='backgroundDownloads' size="xs" type="button" className="btn btn-pdf backgroundDownloads" onClick={() => printWorkOrder(_patients.id_work_order)}>
                                                                                                                <i className="fa fa-file-pdf-o"></i>
                                                                                                                <Label>{_patients.downloads}</Label>
                                                                                                            </Button>
                                                                                                            :
                                                                                                            <Button color='backgroundZeroDownloads' size="xs" type='button' className='btn btn-pdf  backgroundZeroDownloads' onClick={() => printWorkOrder(_patients.id_work_order)}>
                                                                                                                <i className="fa fa-file-pdf-o"></i>
                                                                                                                <Label>{_patients.downloads}</Label>
                                                                                                            </Button>
                                                                                                        }
                                                                                                    </Row>
                                                                                                </Col>

                                                                                            </>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </Row>
                                                                                </td>


                                                                            </tr>



                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                        <Table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: "300px" }}>
                                                                        <p>
                                                                            Mostrando {actualPage} a {resultCount} de {totalRecords} resultados
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <Pagination aria-label='...' style={{ justifyContent: "center" }}>
                                                                            <ul className='pagination pagination-primary'>
                                                                                {
                                                                                    actualPage === 1 ?
                                                                                        <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                                                        : <li style={{ cursor: "pointer" }} className='page-item' onClick={goPreviousPage} ><span className='page-link'>Anterior</span></li>
                                                                                }
                                                                                {
                                                                                    actualPage > 1 &&
                                                                                    <>
                                                                                        <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goPreviousPage(1)} ><span className='page-link'>1</span></li>
                                                                                        {
                                                                                            actualPage > 2 &&
                                                                                            <>
                                                                                                <li className="page-item"><span className='page-link'>...</span></li>
                                                                                                <li style={{ cursor: "pointer" }} onClick={() => goToPage(actualPage - 1)} className='page-item'><span className='page-link'>{actualPage - 1}</span></li>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{actualPage}</span></li>
                                                                                {
                                                                                    actualPage < totalPages &&
                                                                                    <>
                                                                                        {
                                                                                            actualPage < (totalPages - 1) &&
                                                                                            <>
                                                                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage(actualPage + 1)} className='page-item'><span className='page-link'>{actualPage + 1}</span></li>
                                                                                                <li className='page-item'><span className='page-link'>...</span></li>
                                                                                            </>
                                                                                        }
                                                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage(totalPages)} className='page-item'><span className='page-link'>{totalPages}</span></li>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    totalPages === actualPage ?
                                                                                        <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                                                        : <li style={{ cursor: "pointer" }} className='page-item primary' onClick={goNextPage}><span className='page-link primary'>Siguiente</span></li>
                                                                                }
                                                                            </ul>
                                                                        </Pagination>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    )}


                    { activeIndex === 1 && (
                        <>
                            <Row>
                            <Col sm="3" md="3" lg="3">
                                    <Card>
                                        <CardBody className='p-3'>
                                            <Row className="d-flex align-items-center">
                                                <Col sm="auto">
                                                    <i className="fa fa-users f-32 txt-info"></i>
                                                </Col>
                                                <Col sm="auto">
                                                    <label className='f-w-500' style={{ fontSize: "12px" }}>
                                                        Total de pacientes: 
                                                    </label>
                                                </Col>
                                                <Col sm="auto">
                                                    <label className='f-w-500' style={{ fontSize: "18px" }}>
                                                        {lblTotalPatient}

                                                    </label>

                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>


                                <Col sm="9" md="6" lg="9">
                                    <Card>
                                        <CardBody className='p-3'>
                                           
                                            <Row sm="9">
                                                <Col sm="7" md="7" lg="7" className='text-center f-s-italic text-info p-2'>

                                                    <h5 className='f-14 f-w-700'>Reporte cuentas por cobrar </h5>
                                                </Col>
                                                <Col sm="3" md="3" lg="3" className='d-flex'>
                                                    <Input type='month' value={dateFilter} className=' mr-2' onChange={(e) => {
                                                        setDateFilter(e.target.value)
                                                    }} />
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='d-flex'>
                                                    <Button color='info' size='xs' outline onClick={handleFilterDate}>Filtrar periodo</Button>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='d-flex'>
                                                    <Button color='danger' size='xs' outline onClick={handleCleanFilterByClient}>Limpiar periodo</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Card>
                                <CardBody className='pl-2 pb-2 pt-2' >
                                    <Row>
                                        <Col sm="3" style={{ borderRight: "1px solid rgba(0, 0, 0, 0.14)" }} >
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="3">
                                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoicesPaid.svg")} style={{ width: "60px", height: "60px" }} />
                                                    </Col>
                                                    <Col sm="9" >
                                                        <label className='f-w-500 ' style={{ fontSize: "13" }} >Ingresos Mensuales</label>
                                                        <div className='text-center widgets-ind'>
                                                            {
                                                                loadingTotalIngress && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                            }
                                                            {
                                                                !loadingTotalIngress && <h4 className='text-success'><i className="fa fa-usd"></i> {lblTotalIngress}</h4>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                        <Col sm="3" style={{ borderRight: "1px solid rgba(0, 0, 0, 0.14)" }} >
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="3">
                                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoicesPaid.svg")} style={{ width: "60px", height: "60px", filter: "sepia(1)" }} />

                                                    </Col>
                                                    <Col sm="9">
                                                        <label className='f-w-500'>Saldo mensual pendiente </label>
                                                        <div className='text-center widgets-ind'>
                                                            {
                                                                loadingOutsideBalance && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                            }
                                                            {
                                                                !loadingOutsideBalance && <h4 className='text-danger'><i className="icofont icofont-cur-dollar-minus"></i> {lblOutSideBalance}</h4>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>

                                        <Col sm="3" style={{ borderRight: "1px solid rgba(0, 0, 0, 0.14)" }}>
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="3">
                                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoices.svg")} style={{ width: "60px", height: "60px" }} />

                                                    </Col>
                                                    <Col sm="9">
                                                        <label className='f-w-500'>Ingreso mensual pagado</label>
                                                        <div className='text-center widgets-ind'>
                                                            {
                                                                loadingOutsideBalance && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                            }
                                                            {
                                                                !loadingOutsideBalance && <h4 className='text-info'><i className="icofont icofont-cur-dollar-minus"></i> {lblTotalPaidIngress}</h4>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                        <Col sm="3" >
                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="3">
                                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPercentage.svg")} style={{ width: "60px", height: "60px" }} />

                                                    </Col>
                                                    <Col sm="9">
                                                        <label className='f-w-500'>Porcentaje de cumplimiento</label>
                                                        <div className=' widgets-ind '>
                                                            {
                                                                loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                            }
                                                            {
                                                                !loadingDiscount && <h4 className='text-warning f-w-600'>{lblTotalPercentageIngress}</h4>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                            <Row className='mt-2'>
                                <Col sm="12" md="12" lg="12">
                                    <Card>
                                        {detailTransactionsByClients?.length > 0 && detailTransactionsByClients?.map((_orders, _indexOrder) => (
                                            <CardHeader className='p-2'>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <h5 className='card-title f-14' style={{ flexGrow: 1, textAlign: 'center' }}>
                                                Cargos pendientes - saldados
                                                </h5>

                                                <div style={{ cursor: 'pointer', textAlign: 'right' }} onClick={() => DownloadReportCxc(_orders?.id_client)}>
                                                <Badge color='success'>
                                                    <i className='icofont icofont-file-excel f-16'> </i>
                                                      Descargar Reporte
                                                </Badge>
                                                </div>
                                            </div>
                                            </CardHeader>
                                        ))}

                                        
                                        <CardBody className='p-2' >
                                            <div className='text-left'>
                                                <p>Mostrando - {listDetailTransactionsByClients?.length} resultados</p>
                                            </div>
                                            <Col sm="12" className='p-l-0 p-r-0'>
                                                <Row>
                                                    <Col sm="4" className='p-r-0' >
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterAmount ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmount(!filterAmount) }} >Efectivo</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountCredit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountCredit(!filterAmountCredit) }} >Crédito</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountDebit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountDebit(!filterAmountDebit) }} >T.D</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountTcredit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountTcredit(!filterAmountTcredit) }} >T.C</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountTrasferencia ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountTrasferencia(!filterAmountTrasferencia) }} >Transferencia</Label>
                                                    </Col>
                                                    <Col sm="8" className='p-l-0' style={{ textAlign: "right" }} >
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterCredit ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterCredit(!filterCredit) }} >Crédito</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterAccount ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterAccount(!filterAccount) }} >Contado</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPayment(!filterPayment) }} >Pagado</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterPendingPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendingPayment(!filterPendingPayment) }} >Pendiente pagado</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterInvoiced(!filterInvoiced) }} >Facturado</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterPendigInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendigInvoiced(!filterPendigInvoiced) }} >Pendiente de facturar</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterPreInvoice ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPreInvoice(!filterPreInvoice) }} >Prefactura</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterPpd ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPpd(!filterPpd) }} >PPD</Label>
                                                        <Label type="text" className={`f-w-600 f-12 badge badge${filterPue ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPue(!filterPue) }} >PUE</Label>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <div style={{ height: '440px', overflowY: "scroll", scrollBehavior: "smooth" }} className='table-wrapper-patient'>
                                                <Table className='b-r-5 table-striped sm  -hover'>
                                                    <thead className='b-r-5'>
                                                        <tr style={{ backgroundColor: "#033d5b", fontSize: "11px" }}>
                                                            <th className='labeltheadInvoice'>#</th>
                                                            <th className='labeltheadInvoice'>Cliente</th>
                                                            <th className='labeltheadInvoice'>NIM</th>
                                                            <th className='labeltheadInvoice'>Paciente</th>
                                                            <th className='labeltheadInvoice'>Condición de pago</th>
                                                            <th className='labeltheadInvoice'>Estado del pago</th>
                                                            <th className='labeltheadInvoice'>Estatus facturación</th>
                                                            <th className='labeltheadInvoice'>Total</th>
                                                            <th className='labeltheadInvoice'>Importe</th>
                                                            <th className='labeltheadInvoice'>Descuento</th>
                                                            <th className='labeltheadInvoice'>Impuesto (IVA 16%)</th>
                                                            <th className='labeltheadInvoice'>Serie</th>
                                                            <th className='labeltheadInvoice'>Folio</th>
                                                            <th className='labeltheadInvoice'>Método Pago</th>
                                                            <th className='labeltheadInvoice'>Método</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            loadingTableClients && <tr>
                                                                <td colSpan={13} className='text-center'>
                                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            listDetailTransactionsByClients?.length > 0 &&
                                                            listDetailTransactionsByClients?.filter(_transaction => {

                                                                if (filterAmount) {
                                                                    return _transaction?.id_Pay_method === 1
                                                                }
                                                                if (filterAmountCredit) {
                                                                    return _transaction?.id_Pay_method === 10
                                                                }
                                                                if (filterAmountDebit) {
                                                                    return _transaction?.id_Pay_method === 4
                                                                }
                                                                if (filterAmountTcredit) {
                                                                    return _transaction?.id_Pay_method === 2
                                                                }
                                                                if (filterAmountTrasferencia) {
                                                                    return _transaction?.id_Pay_method === 8
                                                                }

                                                                if (filterCredit) {
                                                                    return _transaction?.paymentCondition == "Crédito";
                                                                }

                                                                if (filterAccount) {
                                                                    return _transaction?.paymentCondition == "Pago de contado";
                                                                }

                                                                if (filterPayment) {
                                                                    return _transaction?.paymentStatus == "Pagado";
                                                                }

                                                                if (filterPendingPayment) {
                                                                    return _transaction?.paymentStatus == "Pendiente";
                                                                }

                                                                if (filterInvoiced) {
                                                                    return _transaction?.financialStatus == "Facturado";
                                                                }

                                                                if (filterPendigInvoiced) {
                                                                    return _transaction?.financialStatus == "Pendiente";
                                                                }

                                                                if (filterPreInvoice) {
                                                                    return _transaction?.financialStatus == "Prefactura";
                                                                }

                                                                if (filterPpd) {
                                                                    return _transaction?.payMethod == "PPD";
                                                                }

                                                                if (filterPue) {
                                                                    return _transaction?.payMethod == "PUE";
                                                                }

                                                                if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue) {
                                                                    return _transaction;
                                                                }
                                                            })
                                                                ?.map((_order, _index) => {

                                                                    let paymentMethod;

                                                                    switch (_order.id_Pay_method) {
                                                                        case 1:
                                                                            paymentMethod = "Efectivo";
                                                                            break;
                                                                        case 2:
                                                                            paymentMethod = "T.C";
                                                                            break;
                                                                        case 4:
                                                                            paymentMethod = "T.D";
                                                                            break;
                                                                        case 8:
                                                                            paymentMethod = "T.E";
                                                                            break;
                                                                        case 10:
                                                                            paymentMethod = "Crédito";
                                                                            break;
                                                                        case 28:
                                                                            paymentMethod = "Deposito Bancario";
                                                                            break;
                                                                        default:
                                                                            paymentMethod = "Desconocido";
                                                                            break;
                                                                    }

                                                                    let _total = _order?.totalWorkOrder
                                                                    let _tax = _order?.totalTax

                                                                    const _totalWorkOrder = _total += _tax
                                                                    return <tr key={_order?.idWorkOrder} className={_order?.financialStatus == "S/Factura" ? "tableSF text-white" : ""}>
                                                                        <td style={{ fontSize: "11px" }}>{_index + 1}</td>
                                                                        <td style={{ fontSize: "11px" }}>{_order?.nameClient}</td>
                                                                        <td style={{ fontSize: "11px", color: "#0079C7" }} className='f-w-600' >{_order?.nim}</td>
                                                                        <td style={{ fontSize: "11px" }} >{_order?.namePatient}</td>
                                                                        <td><Label type="text" style={{ fontSize: "11px" }} className={`f-w-600  badge badge-${_order?.paymentCondition === "Cortesia" ? 'danger' : _order?.paymentCondition === "Crédito" ? 'warning' : "secondary"}`}>{_order?.paymentCondition}</Label></td>
                                                                        <td ><Label type="text" style={{ fontSize: "11px" }} className={`f-w-600  badge badge-light-${_order?.paymentStatus === "Pendiente" ? 'warning' : "success"}`}>{_order?.paymentStatus}</Label></td>
                                                                        <td ><Label type="text" style={{ fontSize: "11px" }} className={`f-w-600  badge badge-light-${_order?.financialStatus === "Pendiente" ? 'danger' : _order?.financialStatus == "Prefactura" ? "warning" : "success"}`}>{_order?.financialStatus}</Label></td>
                                                                        <td style={{ fontSize: "11px" }} className='f-w-700'>${number_format(_totalWorkOrder ?? 0, 2)}</td>
                                                                        <td style={{ fontSize: "11px" }} className='f-w-600'>$ {number_format(_order?.totalWorkOrder, 2)}</td>
                                                                        <td style={{ fontSize: "11px" }} className='font-danger f-w-600'>$ {number_format(_order?.totalDiscount, 2)}</td>
                                                                        <td style={{ fontSize: "11px" }} className='f-w-600 text-seconday'>$ {number_format(_order?.totalTax, 2)}</td>
                                                                        <td style={{ fontSize: "11px" }}>{_order?.serie}</td>
                                                                        <td style={{ fontSize: "11px" }}>{_order?.folio}</td>
                                                                        <td style={{ fontSize: "11px" }} className='f-w-700'>{_order?.payMethod}</td>
                                                                        <td style={{ fontSize: "11px" }} className='f-w-700 txt-success'>{paymentMethod}</td>
                                                                    </tr>
                                                                })
                                                        }
                                                        {totalRowPatient}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>

                    )}

                </Container>
                <ModalPreview
                    {...{
                        modalPreview, togglePreview, handlePreview, typeModall, loadingPdf, previewWorkOrder, workMedicalOrderPDF
                    }}
                />
            </>

        </Fragment>
    );
}

export default Sampl;