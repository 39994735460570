import React, {useState} from 'react';
import { Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { Col, Row, Table, Badge, Pagination, Button,Form,FormGroup,Label,Input } from 'reactstrap';
import './styleCash.css';
import Select from 'react-select';
import { cedimi} from '../../constant/globalConstantsTypeClients';



export const ModalReportCash = (
    { close, formatter, data, branchs, totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1, nextPage = null, previousPage = null, dataPaymets,
        dataCancell, dataDiscount, dataPatient, mostrar, mDateRange1, mDateRange2, handleInputChangeCash, loading, getReport, mSelectBranch,
        handleSelectValuesCash, branches, viewFormFilters, handleCloseViewTest, dataCredit, printCashClosing, totalPaymets, exportToExcel, dataMDiscount,
        handleClean,dataUser,ids_users,type_company,total_devolution,_active_micro,_url_helen,
        ...props }) => {
    console.log("🚀 ~ data:", data)

    //*CONFIGURATION TABLE
    const [expandedRow, setExpandedRow] = useState(null);

    const handleExpandRow = (index) => {
        if (expandedRow === index) {
          setExpandedRow(null);
        } else {
          setExpandedRow(index);
        }
      };


    //*CONFIGURATON FINANCE
    let _valid_Finance = localStorage.getItem('finance');
    let _finance2 = localStorage.getItem('finance_view');

    let _validationFinance = (_valid_Finance === "True" &&  _finance2 === "False") ? true : false


    return (
        <Modal {...props} padding={false} closeButton={false}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div className='pt-2'  >
                <Col sm="12">
                            <Row>
                                <br />
                                <Col sm="11" className={`${viewFormFilters ? 'm-t-11' : 'd-none'} `} >
                                    <Form onSubmit={(e) => getReport(e, true)} >
                                        <Row>
                                            <Col sm="2" className='p-1' >
                                                <FormGroup>
                                                    <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Fecha de inicio</Label>
                                                    <Input type='date' value={mDateRange1} name='mDateRange1' onChange={handleInputChangeCash} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" className='p-1' >
                                                <FormGroup>
                                                    <Label className='f-w-600 f-12 badge badge-light-primary'>Fecha de cierre</Label>
                                                    <Input type='date' value={mDateRange2} name="mDateRange2" onChange={handleInputChangeCash} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Sucursal</Label>
                                                    <Select
                                                        name="mSelectBranch"
                                                        isClearable={true}
                                                        placeholder="Selecciona una sucursal"
                                                        options={branches}
                                                        onChange={(e) => handleSelectValuesCash(e, 'mSelectBranch')}
                                                        value={mSelectBranch}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                           
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Usuarios</Label>
                                                    <Select
                                                        name="ids_users"
                                                        isClearable={true}
                                                        placeholder="Selecciona una sucursal"
                                                        options={dataUser}
                                                        onChange={(e) => handleSelectValuesCash(e, 'ids_users')}
                                                        value={ids_users}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                           
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Tipo de Ingreso</Label>
                                                    <Select
                                                        name="type_company"
                                                        isClearable={true}
                                                        placeholder="Selecciona un cliente"
                                                        options={[
                                                            { "value": 1, "label": "Particular" },
                                                            { "value": 2, "label": "Empresas" }
                                                          
                                                        ]}
                                                        onChange={(e) => handleSelectValuesCash(e, 'type_company')}
                                                        value={type_company}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="1">
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Acciones Rapidas</Label> <br />
                                                <Button type="submit" outline color="primary" size='sm' className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} >Filtrar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                </div>
                <div style={{ display: "flex"}}>
                    <button className="butto-full-modal" onClick={close}>
                        <label className='f-20'>X</label>
                    </button>
                </div>
                <Table >
                    <tbody>
                        <tr>
                            <td>
                                <Pagination aria-label='...' style={{ justifyContent: "left" }}>
                                    <ul className='pagination pagination-primary'>
                                        {
                                            currentPage1 === 1 ?
                                                <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                : <li style={{ cursor: "pointer" }} className='page-item' onClick={previousPage1} ><span className='page-link'>Anterior</span></li>
                                        }
                                        {
                                            currentPage1 > 1 &&
                                            <>
                                                <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goToPage1(1)} ><span className='page-link'>1</span></li>
                                                {
                                                    currentPage1 > 2 &&
                                                    <>
                                                        <li className="page-item"><span className='page-link'>...</span></li>
                                                        <li style={{ cursor: "pointer" }} onClick={() => goToPage1(currentPage1 - 1)} className='page-item'><span className='page-link'>{currentPage1 - 1}</span></li>
                                                    </>
                                                }
                                            </>
                                        }
                                        <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage1}</span></li>
                                        {
                                            currentPage1 < totalPageCount1 &&
                                            <>
                                                {
                                                    currentPage1 < (totalPageCount1 - 1) &&
                                                    <>
                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage1(currentPage1 + 1)} className='page-item'><span className='page-link'>{currentPage1 + 1}</span></li>
                                                        <li className='page-item'><span className='page-link'>...</span></li>
                                                    </>
                                                }
                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage1(totalPageCount1)} className='page-item'><span className='page-link'>{totalPageCount1}</span></li>
                                            </>
                                        }
                                        {
                                            totalPageCount1 === currentPage1 ?
                                                <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                : <li style={{ cursor: "pointer" }} className='page-item primary' onClick={nextPage}><span className='page-link primary'>Siguiente</span></li>
                                        }
                                    </ul>
                                </Pagination>
                            </td>
                            <td style={{ width: "88px" }}  >
                                <Button type="button" outline color="danger" size='xs' onClick={printCashClosing} className={(loading ? "disabled progress-bar-animated progress-bar-striped ml-1 text-center" : "text-center ml-1")} >
                                    <i class="icofont icofont-file-pdf text-danger"></i> PDF
                                </Button>
                            </td>
                            <td style={{ width: "88px" }}>
                                <Button type="button" size="xs" outline color="success" className={(loading ? "disabled progress-bar-animated progress-bar-striped ml-1 text-center" : "text-center ml-1")} onClick={() => exportToExcel(data)}>
                                    <i className="icofont icofont-file-excel" /> Excel
                                </Button>
                            </td>
                            <td style={{ width: "85px" }}>
                                <Button outline color="primary" onClick={handleClean} size='xs' className={(loading ? "disabled progress-bar-animated progress-bar-striped ml-1 text-center" : "text-center ml-1")} >Limpiar</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div style={{ position: "relative" }}>
                    <Col sm="12" className={`${viewFormFilters ? 'm-t-10  p-l-0 p-r-0' : 'd-none'} `}  >
                        <Col sm="12" className='' >
                            <div className='scroll-container-cancell'>
                                <Table striped size='xs'>
                                    <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#94bcd5", color: "#fff", fontWeight: "bold",  position: "sticky", left: 0, zIndex: 3}}></th>
                                        <th style={{ backgroundColor: "#1f6cd9", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",  position: "sticky", left: "64px", zIndex: 3}}>
                                            {_validationFinance ? "#" : "Solicitud(NIM)"}
                                        </th>
                                        <th className={ (_active_micro === "True" && _url_helen !== cedimi) ? "" : "d-none" } style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold", minWidth: "100px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",  position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "199px" : null, zIndex: 3,}}>Tipo</th>
                                        <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold", minWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "299px" : "199px", zIndex: 3 }}>Paciente</th>
                                        <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold", minWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "449px" : "349px", zIndex: 3 }}>Sucursal</th>
                                        <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold", minWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", padding:"0px",  position: "sticky",  left: _active_micro === "True" && _url_helen !== cedimi ? "599px" : "499px", zIndex: 3 }}>Tipo de Ingreso</th>
                                        <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold", minWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "749px" : "649px", zIndex: 3  }}>Usuario / Hora</th>
                                        <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold", minWidth: "160px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Tarjeta de Credito</th>
                                        <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold", minWidth: "160px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Tarjeta de Debito</th>
                                        <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Efectivo</th>
                                        <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Transferencia</th>
                                        <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Credito</th>
                                        <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Total</th>
                                        <th style={{ backgroundColor: "#0079c7", color: "#fff", fontWeight: "bold", minWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Fecha de Ingreso</th>
                                        <th style={{ backgroundColor: "#0079c7", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Pago</th>
                                        <th style={{ backgroundColor: "#0079c7", color: "#fff", fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Orden</th>
                                        <th style={{ backgroundColor: "#0079c7", color: "#fff", fontWeight: "bold", minWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Facturación</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading && <tr>
                                                <td colSpan={17} className='bgTableCash text-center f-16'>
                                                    <i className="fa fa-spin fa-spinner"></i> Cargando...
                                                </td>
                                            </tr>
                                        }
                                       {
                                        !loading && data !== undefined && data?.length > 0 && data?.map((a, b) => {
                                            if (a.visibleTable) {

                                                let _valid_Color = (a.id_company !== null ) ? "secondary" : (a.amountDiscount === 0) ?  "secondary" : "danger"
                                                
                                                //!VALIDATIONS FINANCE METHODS
                                                if (_valid_Finance === "True") {
                                                    if (_finance2 === "False") {
                                                        if (a.complete_information === "True") {
                                                            return;   
                                                        }
                                                    }
                                                }
                                                return (
                                                    <React.Fragment key={a?.id}>
                                                        <tr style={a.status === "Vigente" ? {} : { backgroundColor: "#d8000078", color: "#fff !important" }}>
                                                        <td style={{ verticalAlign: "middle", textAlign: "center",padding: "0", position: "sticky", left: 0, zIndex: 3,backgroundColor: "#fff" }}>
                                                            {expandedRow === b ? (
                                                                <Button
                                                                    outline
                                                                    color="danger"
                                                                    size="xs"
                                                                    style={{
                                                                        borderRadius: "50%",
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        fontSize: "14px",
                                                                        padding: "0"  
                                                                    }}
                                                                    onClick={() => handleExpandRow(b)}  
                                                                >
                                                                    -
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    outline
                                                                    color={_valid_Color}
                                                                    size="xs"
                                                                    style={{
                                                                        borderRadius: "50%",
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        fontSize: "14px",
                                                                        padding: "0"  
                                                                    }}
                                                                    onClick={() => handleExpandRow(b)} 
                                                                >
                                                                    +
                                                                </Button>
                                                            )}
                                                        </td>
                                                            <td style={{ verticalAlign: "middle",padding: "0",position: "sticky", left: "64px", zIndex: 3, backgroundColor: "#fff" }}>{_validationFinance ? <>{b + 1 }</> : <> {a?.nim ?? "--"} </>}</td>
                                                            <td className={ (_active_micro === "True" && _url_helen !== cedimi) ? "" : "d-none" } style={{ verticalAlign: "middle" ,padding: "0", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "199px" : null, zIndex: 3, backgroundColor: "#fff"}}>
                                                                <Col sm="12" className='p-l-0 ' >
                                                                    <Row>
                                                                        <Col sm="1" className='p-l-0 p-r0' >
                                                                        </Col>
                                                                        <Col sm="7">
                                                                        <Col sm="12" className={a?.is_micro ? "badge-detail-M" : "badge-detail-C"} title={a?.is_micro ? "Microsanitaria" : "Clinicos"}> <i className="fa fa-angle-right f-12"></i>&nbsp;&nbsp;&nbsp;{a?.is_micro ? "M" : "C"}</Col>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "299px" : "199px", zIndex: 3, backgroundColor: "#fff" }}>{a?.namePatiente ?? "--"}</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "449px" : "349px", zIndex: 3, backgroundColor: "#fff"}}>{a?.name_branches ?? "--"}</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "599px" : "499px", zIndex: 3, backgroundColor: "#fff"}}>
                                                                <Col sm="12" className='p-l-0 p-r-0 pt-2' >
                                                                <Row>
                                                                <Col sm="1" >
                                                                </Col>
                                                                    <Col sm="4" className='p-l-0 p-r-0' >
                                                                        <Col sm="12" className={a?.type === "Empresas" ? "badge-detail-type" : "badge-detail-type-patient"}  >
                                                                         <i className="fa fa-angle-right f-12"></i>&nbsp;&nbsp;&nbsp;{a?.type === "Empresas" ? "Empresa" : "Publico G."}
                                                                    </Col>
                                                                    </Col>
                                                                    <Col sm="12" className={a?.type === "Empresas"  ? '' : "d-none" }  >
                                                                    <label className='f-w-600' style={{fontSize:"9px"}} >                                                                    
                                                                        {a?.type === "Empresas" ? a?.name_company ?? "--" : ""}
                                                                    </label>
                                                                    </Col>
                                                                </Row>
                                                                </Col>
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0", position: "sticky", left: _active_micro === "True" && _url_helen !== cedimi ? "749px" : "649px", zIndex: 3,  backgroundColor: "#fff"  }}>
                                                                <Col sm="12" className='p-l-0 p-r-0' >
                                                                <Row>
                                                                    <Col sm="12" >
                                                                        {a?.user ?? "--"}
                                                                    </Col>
                                                                    <Col sm="12" style={{textAlign :"center"}} >
                                                                        <b style={{fontSize:"9px"}} >{a.hour ?? "--"}</b>
                                                                    </Col>
                                                                </Row>
                                                                </Col>
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }}>{formatter.format(a?.creditCard ?? 0)}</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }}>{formatter.format(a?.debitCard ?? 0) }</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }}>{formatter.format(a?.cash ?? 0)}</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }}>{formatter.format(a?.electronicTransfer ?? 0 )}</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }}>${(a?.credit.toFixed(2) ?? 0)} </td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }} className='p-l-0 p-r-0' >
                                                            {a.ajuste ?<div><b className={a?.ajuste === true ? "labelIndicadorCash" : "d-none"} >Saldo recuperado</b><br /></div>: ""}
                                                            {(a.status === "Vigente" ? <div> {formatter.format(a?.amount ?? 0)}</div> : "")}
                                                            </td>
                                                            <td style={{textAlign:"center",padding: "0", verticalAlign: "middle"}}>{a?.status === "Pendiente" ? "N/A" : a?.fech_admision}</td>
                                                            <td style={{ verticalAlign: "middle" ,padding: "0" }}>{a?.is_fullPaid ?? "--"}</td>
                                                            <td style={{ textAlign: "center", verticalAlign: "middle" ,padding: "0" }}>
                                                            <Col sm="12" className='p-l-0 p-r-0' >
                                                                <Row>
                                                                    <Col sm="1">
                                                                    </Col>
                                                                    <Col sm="7" className='p-r-0'>
                                                                        <Col sm="12" className={ a?.status === "Vigente" ? "badge-detail-status p-l-0 p-r-0 " :" badge-detail-status2 p-l-0 p-r-0"}  >
                                                                         <i className="fa fa-angle-right f-12"></i>&nbsp;  {a?.status ?? ""}&nbsp;&nbsp;
                                                                    </Col>
                                                                    </Col>
                                                                </Row>
                                                                </Col>
                                                            </td>
                                                            <td style={{ textAlign: "center", verticalAlign: "middle" ,padding: "0" }}>
                                                                <Col sm="12" className='p-l-0 p-r-0' >
                                                                <Row>
                                                                    <Col sm="1">
                                                                    </Col>
                                                                    <Col sm="4" className='p-l-0 p-r-0' >
                                                                        <Col sm="12" className={ a?.is_invoice === "Facturado" ? "badge-detail-Invoice2 p-l-0 p-r-0 " :" badge-detail-Invoice p-l-0 p-r-0"}  >
                                                                         <i className="fa fa-angle-right f-12"></i>&nbsp; {a?.is_invoice ?? ""}
                                                                    </Col>
                                                                    </Col>
                                                                </Row>
                                                                </Col>
                                                            </td>
                                                        </tr>
                                                        {expandedRow === b && (
                                                            <tr style={{backgroundColor : "rgb(3 61 91 / 6%)"}}>
                                                                <td colSpan={1}></td>
                                                                <td colSpan={4} style={{ textAlign: "justify" }}>
                                                                    <b className='text-danger f-12'>Tipo de Descuento</b> 
                                                                    <br />
                                                                    <label className='branches-label'>
                                                                    {
                                                                        a?.name_Preloaded_discount !== "" ? (a?.name_Preloaded_discount) : a?.nameCommont !== "" ? (a?.nameCommont) : "N/A"
                                                                    }
                                                                    </label>
                                                                </td>
                                                                <td colSpan={2} style={{ textAlign: "justify" }}>
                                                                    <b className='text-danger f-12'>Porcentaje de descuento</b>  
                                                                    <br />
                                                                    <label className='branches-label'>
                                                                    {
                                                                        a?.name_Preloaded_discount !== "" ? (a?.percentagePreloaded_discount) : a?.nameCommont !== "" ? (a?.amountPercentag) : "N/A"
                                                                    }
                                                                    </label>
                                                                </td>
                                                                <td colSpan={2} style={{ textAlign: "justify" }}>
                                                                    <b className='text-danger f-12'>Descuento aplicado</b>  
                                                                    <br />
                                                                    <label className='branches-label'>
                                                                    {
                                                                        a?.name_Preloaded_discount !== "" ? (a?.amountDiscount ) : a?.nameCommont !== "" ?(a?.amountDiscount) : "N/A"
                                                                    }
                                                                    </label>
                                                                </td>
                                                                {a?.ajuste  && <td colSpan={3} style={{ textAlign: "justify" }}>
                                                                    <b className='labelIndicadorCash f-12'>Usuario / S.R</b> 
                                                                    <br />
                                                                    <label className='branches-label f-12'>{a?.ajuste ? (a?.nameUserAdjust + " " + a?.fech_Adjust) : "" }</label>
                                                                </td>}
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            }
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <br /> <br />
                        <Col sm="12">
                            <Row >
                                <Col sm="4">
                                    <div className="table-responsive">
                                        <Table size='sm' className="table-border-vertical">
                                            <thead className="theadInvoice">
                                                <tr className='text-center'>
                                                    <th style={{ textAlign: "center" }} className='labelThead'>MÉTODO DE PAGO</th>
                                                    <th style={{ textAlign: "center" }} className='labelThead'>TOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                !loading && dataPaymets.map((obj, key) => {
                                                        if (obj.amount == 0 ) {
                                                            return;
                                                        }
                                                        return (
                                                            <tr key={key}>
                                                                <td style={{ textAlign: "center" }} >{obj.pay_method}</td>
                                                                <td style={{ textAlign: "center" }}> {formatter.format(obj.amount?? 0)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <thead >
                                                {
                                                     !loading && <tr className='text-center'>
                                                     <th > <label className='f-w-600 f-12 badge badge-light-primary '>Total Global</label> </th>
                                                     <th > <b className='f-14' >{formatter.format(totalPaymets?? 0)} </b></th>
                                                 </tr>

                                                }
                                           
                                            </thead>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="4">
                                </Col>
                                <Col sm="4">
                                    <div className="table-responsive">
                                        <Table size='sm' className="table-border-vertical">
                                            <thead className="theadInvoice">
                                                <tr className='text-center'>
                                                    <th colSpan={2} className='labelThead'>DETALLE DE MOVIMIENTOS</th>
                                                </tr>
                                            </thead>
                                            <tbody>                                           
                                                <tr>
                                                    <td>CANCELACIONES</td>
                                                    {
                                                        !loading && <td>{formatter.format(dataCancell?? 0)}</td>
                                                    }
                                                </tr>
                                               
                                                <tr>
                                                    <td>DESCUENTOS</td>
                                                    {
                                                        !loading && <td> {formatter.format(dataMDiscount?? 0)} </td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>TOTAL DE PACIENTES</td>
                                                    {
                                                        !loading && <td>{dataPatient}</td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Col> <br />
                    </Col> <br />
                </div>


            </div> <br />
        </Modal >
    )
}


